// easings
$ease-out-cubic: cubic-bezier(0.2, 0.5, 0.3, 1);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$wind-easing: cubic-bezier(0.11, 0.815, 0.195, 0.95);

// fonts
$font-pt-sans: "PT Sans", sans-serif;
$font-montserrat: "Gilroy", sans-serif;
$font-roboto: "Roboto", sans-serif;

$text-font: $font-pt-sans;
$button-font: $font-pt-sans;
$title-font: $font-montserrat;

// font-size
$base-font-size--mobile: 16px;
$base-font-size: 18px;

$font-size-1--tablet: 3rem;
$font-size-1: 3.4375rem;
%font-size-1 {
  font-size: $font-size-1--tablet;
}

$font-size-2--tablet: 2.25rem;
$font-size-2: 2.75rem;
%font-size-2 {
  font-size: $font-size-2--tablet;
}

$font-size-3: 1.5rem;
%font-size-3 {
  font-size: $font-size-3;
}

$font-size-4: 1rem;
%font-size-4 {
  font-size: $font-size-4;
}

$font-size-5: 0.85rem;
%font-size-5 {
  font-size: $font-size-5;
}

$font-size-6: 0.725rem;
%font-size-6 {
  font-size: $font-size-6;
}
