%hidden-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

%fill-absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

%fill-fixed {
  @extend %fill-absolute;
  position: fixed;
}

%reset-list-style {
  &,
  li {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li::before {
    content: "";
    display: none;
  }
}

%bg-cover {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

%bg-contain {
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

%h-menu {
  ul,
  li {
    list-style: none;
    padding: 0;
  }

  ul {
    margin: 0;
    display: block;
    overflow: auto;
    white-space: nowrap;
  }

  li {
    display: inline-block;
    white-space: normal;
    margin: 4px;
  }
}

%inline-block-middle {
  display: inline-block;
  vertical-align: middle;
}

%wrap-long-words {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

%ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

%uppercase {
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

%defaultcase {
  text-transform: none;
  letter-spacing: 0;
}

%reset-title-style {
  font-size: 1rem;
  font-weight: normal;
  font-family: "PT Sans", sans-serif;
  margin-top: 0;
  margin-bottom: 0;
}

%fullscreen-offset {
  padding: 36px 24px;
  @include media-breakpoint-from(md) {
    padding: 5vw;
  }
  @media only screen and (min-width: 1441px) {
    padding: 6vw;
  }
}

.fullscreen-padding {
  @extend %fullscreen-offset;
}


%transform-text-blink-fix {
  -webkit-font-smoothing: subpixel-antialiased;
  /*
    * The fix for WebKit/Blink browsers.
    */
  -webkit-backface-visibility: hidden;

  /*
    * The fix for Firefox
    */
  // filter: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><filter id="gaussian_blur"><feGaussianBlur in="SourceGraphic" stdDeviation="0" /></filter></defs></svg>#gaussian_blur');
}

%default-description {
  font-size: 13px;
  color: #8c8c8c;
  font-weight: normal;
  font-family: "PT Sans", sans-serif;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1.4;
}