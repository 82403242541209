@import "brigrid/core/brigrid";
@import '../../styles/base/placeholders';
@import '../../styles/base/variables';

.formOverlay {
    @extend %fill-fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: #000;

    transform: translate3d(0, 100%, 0);
    transition: transform .5s ease;

    &:global(.modal-show-enter-done) {
        transform: translate3d(0, 0%, 0);

        .modal {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    &:global(.modal-show-exit) {
        transform: translate3d(0, -100%, 0);
        transition: transform .5s ease .5s;


        .modal {
            opacity: 0;
            transition: opacity .5s ease, transform .5s ease;
            transform: translate3d(0, -100%, 0);
        }
    }
}

.overflowContainer {
    overflow: hidden;
}

.modal {
    font-family: $font-montserrat;
    font-size: 1.5rem;

    opacity: 0;
    transform: translate3d(0, 100%, 0);
    transition: opacity .5s ease .5s, transform .5s ease .5s;

    @include media-breakpoint-from(md) {
        font-size: 2rem;
    }

    @include media-breakpoint-from(xl) {
        font-size: 2.5rem;
    }
}