@charset "UTF-8";
/**
 * Import npm dependencies
 *
*/
@import url(../node_modules/normalize.css/normalize.css);
.wrapper, .wrapper--extra-small, .wrapper--small, .wrapper--large, .page-content-wrapper {
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box; }

.grid-container {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box; }

.grid-col {
  box-sizing: border-box;
  position: relative;
  min-height: 1px;
  width: 100%; }

/** Import everything from autoload */
@font-face {
  font-family: "fontello";
  src: url("./fonts/fontello/fontello.eot?40699992");
  src: url("./fonts/fontello/fontello.eot?40699992#iefix") format("embedded-opentype"), url("./fonts/fontello/fontello.woff2?40699992") format("woff2"), url("./fonts/fontello/fontello.woff?40699992") format("woff"), url("./fonts/fontello/fontello.ttf?40699992") format("truetype"), url("./fonts/fontello/fontello.svg?40699992#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?40699992#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-location:before {
  content: '\e800'; }

/* '' */
.icon-cancel:before {
  content: '\e801'; }

/* '' */
.icon-heart-empty:before {
  content: '\e802'; }

/* '' */
.icon-heart:before {
  content: '\e803'; }

/* '' */
.icon-facebook:before {
  content: '\f09a'; }

/* '' */
.icon-vk:before {
  content: '\f189'; }

/* '' */
.icon-behance:before {
  content: '\f1b4'; }

/* '' */
.icon-instagram:before {
  content: '\f32d'; }

/* '' */
/** Import base styles */
.fade-enter {
  opacity: 0; }

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 240ms 240ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 240ms ease; }

.transition-group {
  position: relative; }

.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0; }

.slide-left {
  overflow: hidden;
  transform-origin: 0 0;
  transform: translateX(-100%) translateZ(0); }
  .slide-left__inner {
    transform: translateX(110%) scale(1.1) translateZ(0); }

.slide-right {
  overflow: hidden;
  transform-origin: 0 0;
  transform: translateX(100%) translateZ(0); }
  .slide-right__inner {
    transform: translateX(-110%) scale(1.1) translateZ(0); }

@keyframes pulse {
  0% {
    background-color: rgba(255, 255, 255, 0); }
  50% {
    background-color: rgba(255, 255, 255, 0.03); }
  100% {
    background-color: rgba(255, 255, 255, 0); } }

@keyframes opacity-pulse {
  0% {
    opacity: 1; }
  6% {
    opacity: 0.84034; }
  12% {
    opacity: 0.84746; }
  19% {
    opacity: 0.9434; }
  36% {
    opacity: 0.81967; }
  42% {
    opacity: 1; }
  51% {
    opacity: 0.84034; }
  53% {
    opacity: 0.88496; }
  74% {
    opacity: 0.82645; }
  90% {
    opacity: 0.84746; }
  93% {
    opacity: 1; }
  100% {
    opacity: 1; } }

.primary-checkbox-btn__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.page__bg, .burger-content, .loading-screen, .primary-modal, .loading-screen__spin::before, .portfolio-card__image-container::before, .contact-form__v-lines, .page-home__scene, .page-home-mosaic__mask, .about-cards-item__image-container, .about-cards-item__image, .about-cards-item__content, .about-projects-section__big-button::after, .about-technologies-section-card img, .page-not-found-mosaic__mask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%; }

.page__bg, .burger-content, .loading-screen, .primary-modal {
  position: fixed; }

.page__bg--moon, .burger-content-image, .about-cards-item__image, .about-video-section__video-container, .about-immersion-section {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat; }

.about-video-section__image {
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat; }

.language-switcher__item, .primary-checkbox-btn, .portfolio-card__category {
  display: inline-block;
  vertical-align: middle; }

body, .page-left-page-btn__text, .page-right-page-btn__text, .burger-content-menu li, .language-switcher__item, .side-info-link, .page-home__title, .page-home__description {
  text-transform: uppercase;
  letter-spacing: 0.15em; }

.portfolio-card__description, .contact-form__description, .about-cards-item__description, .about-start-text-col__description, .about-big-image-section__description, .about-projects-section__description, .about-immersion-section__description, .about-technologies-section__description, .about-not-afraid-section__description, .about-prices-section__description, .about-prices-section-price__description {
  text-transform: none;
  letter-spacing: 0; }

.about-immersion-section__title, .about-prices-section-price__title {
  font-size: 1rem;
  font-weight: normal;
  font-family: "PT Sans", sans-serif;
  margin-top: 0;
  margin-bottom: 0; }

.fullscreen-padding, .page--with-padding, .lines-bg, .burger-content, .header, .layout-bottom, .contact-form__content, .footer, .primary-modal, .about-video-section, .about-immersion-section {
  padding: 36px 24px; }
  @media only screen and (min-width: 768px) {
    .fullscreen-padding, .page--with-padding, .lines-bg, .burger-content, .header, .layout-bottom, .contact-form__content, .footer, .primary-modal, .about-video-section, .about-immersion-section {
      padding: 5vw; } }
  @media only screen and (min-width: 1441px) {
    .fullscreen-padding, .page--with-padding, .lines-bg, .burger-content, .header, .layout-bottom, .contact-form__content, .footer, .primary-modal, .about-video-section, .about-immersion-section {
      padding: 6vw; } }

.page-left-page-btn__text, .page-right-page-btn__text, .about-big-image-section__title span, .about-video-section__text {
  -webkit-font-smoothing: subpixel-antialiased;
  /*
    * The fix for WebKit/Blink browsers.
    */
  -webkit-backface-visibility: hidden;
  /*
    * The fix for Firefox
    */ }

.page-not-found-box__description {
  font-size: 13px;
  color: #8c8c8c;
  font-weight: normal;
  font-family: "PT Sans", sans-serif;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1.4; }

@font-face {
  font-family: 'Gilroy';
  src: url("./fonts/Gilroy/hinted-subset-Gilroy-ExtraBold.eot");
  src: local("Gilroy ExtraBold"), local("Gilroy-ExtraBold"), url("./fonts/Gilroy/hinted-subset-Gilroy-ExtraBold.eot?#iefix") format("embedded-opentype"), url("./fonts/Gilroy/hinted-subset-Gilroy-ExtraBold.woff2") format("woff2"), url("./fonts/Gilroy/hinted-subset-Gilroy-ExtraBold.woff") format("woff"), url("./fonts/Gilroy/hinted-subset-Gilroy-ExtraBold.ttf") format("truetype"), url("./fonts/Gilroy/hinted-subset-Gilroy-ExtraBold.svg#Gilroy-ExtraBold") format("svg");
  font-weight: 800;
  font-style: normal; }

/* cyrillic */
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 400;
  src: local("PT Sans Italic"), local("PTSans-Italic"), url(https://fonts.gstatic.com/s/ptsans/v9/jizYRExUiTo99u79D0e0w8mIAjcQ-woy.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 400;
  src: local("PT Sans Italic"), local("PTSans-Italic"), url(https://fonts.gstatic.com/s/ptsans/v9/jizYRExUiTo99u79D0e0x8mIAjcQ-w.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  src: local("PT Sans"), local("PTSans-Regular"), url(https://fonts.gstatic.com/s/ptsans/v9/jizaRExUiTo99u79D0aExcOPIDUg-g.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  src: local("PT Sans"), local("PTSans-Regular"), url(https://fonts.gstatic.com/s/ptsans/v9/jizaRExUiTo99u79D0KExcOPIDU.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  src: local("PT Sans Bold"), local("PTSans-Bold"), url(https://fonts.gstatic.com/s/ptsans/v9/jizfRExUiTo99u79B_mh0OqtLR8a8zILig.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  src: local("PT Sans Bold"), local("PTSans-Bold"), url(https://fonts.gstatic.com/s/ptsans/v9/jizfRExUiTo99u79B_mh0O6tLR8a8zI.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  src: local("PT Sans Bold"), local("PTSans-Bold"), url(https://fonts.gstatic.com/s/ptsans/v9/jizfRExUiTo99u79B_mh0O6tLR8a8zI.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fABc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

h1 {
  font-size: 3rem; }
  @media only screen and (min-width: 992px) {
    h1 {
      font-size: 3.4375rem; } }

h2 {
  font-size: 2.25rem; }
  @media only screen and (min-width: 992px) {
    h2 {
      font-size: 2.75rem; } }

h3 {
  font-size: 1.5rem; }

h4 {
  font-size: 1rem; }

h5 {
  font-size: 0.85rem; }

h6 {
  font-size: 0.725rem; }

html,
body {
  margin: 0;
  padding: 0; }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.wrapper, .wrapper--extra-small, .wrapper--small, .wrapper--large, .page-content-wrapper {
  padding-left: 16px;
  padding-right: 16px;
  max-width: 1218px; }
  @media only screen and (min-width: 768px) {
    .wrapper, .wrapper--extra-small, .wrapper--small, .wrapper--large, .page-content-wrapper {
      padding-left: 24px;
      padding-right: 24px; } }

.grid-container {
  margin-left: -12px;
  margin-right: -12px;
  flex-direction: row; }

.grid-container--h-start {
  justify-content: flex-start; }

.grid-container--h-center {
  justify-content: center; }

.grid-container--h-end {
  justify-content: flex-end; }

.grid-container--h-around {
  justify-content: space-around; }

.grid-container--h-between {
  justify-content: space-between; }

.grid-container--v-start {
  align-items: flex-start; }

.grid-container--v-center {
  align-items: center; }

.grid-container--v-end {
  align-items: flex-end; }

.grid-container--v-baseline {
  align-items: baseline; }

.grid-container--v-stretch {
  align-items: stretch; }

.grid-col {
  padding-left: 12px;
  padding-right: 12px; }

.grid-col-1 {
  display: block;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.grid-col-2 {
  display: block;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.grid-col-3 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%; }

.grid-col-4 {
  display: block;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.grid-col-5 {
  display: block;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.grid-col-6 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%; }

.grid-col-7 {
  display: block;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.grid-col-8 {
  display: block;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.grid-col-9 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%; }

.grid-col-10 {
  display: block;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.grid-col-11 {
  display: block;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.grid-col-12 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%; }

.grid-col-flex {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.grid-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

@media only screen and (max-width: 575px) {
  .grid-col-1-xs {
    display: block;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .grid-col-2-xs {
    display: block;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .grid-col-3-xs {
    display: block;
    flex: 0 0 25%;
    max-width: 25%; }
  .grid-col-4-xs {
    display: block;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .grid-col-5-xs {
    display: block;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .grid-col-6-xs {
    display: block;
    flex: 0 0 50%;
    max-width: 50%; }
  .grid-col-7-xs {
    display: block;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .grid-col-8-xs {
    display: block;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .grid-col-9-xs {
    display: block;
    flex: 0 0 75%;
    max-width: 75%; }
  .grid-col-10-xs {
    display: block;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .grid-col-11-xs {
    display: block;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .grid-col-12-xs {
    display: block;
    flex: 0 0 100%;
    max-width: 100%; }
  .grid-col-flex-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .grid-col-auto-xs {
    flex: 0 0 auto;
    width: auto;
    max-width: none; } }

@media only screen and (min-width: 576px) {
  .grid-col-1-sm {
    display: block;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .grid-col-2-sm {
    display: block;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .grid-col-3-sm {
    display: block;
    flex: 0 0 25%;
    max-width: 25%; }
  .grid-col-4-sm {
    display: block;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .grid-col-5-sm {
    display: block;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .grid-col-6-sm {
    display: block;
    flex: 0 0 50%;
    max-width: 50%; }
  .grid-col-7-sm {
    display: block;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .grid-col-8-sm {
    display: block;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .grid-col-9-sm {
    display: block;
    flex: 0 0 75%;
    max-width: 75%; }
  .grid-col-10-sm {
    display: block;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .grid-col-11-sm {
    display: block;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .grid-col-12-sm {
    display: block;
    flex: 0 0 100%;
    max-width: 100%; }
  .grid-col-flex-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .grid-col-auto-sm {
    flex: 0 0 auto;
    width: auto;
    max-width: none; } }

@media only screen and (min-width: 768px) {
  .grid-col-1-md {
    display: block;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .grid-col-2-md {
    display: block;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .grid-col-3-md {
    display: block;
    flex: 0 0 25%;
    max-width: 25%; }
  .grid-col-4-md {
    display: block;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .grid-col-5-md {
    display: block;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .grid-col-6-md {
    display: block;
    flex: 0 0 50%;
    max-width: 50%; }
  .grid-col-7-md {
    display: block;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .grid-col-8-md {
    display: block;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .grid-col-9-md {
    display: block;
    flex: 0 0 75%;
    max-width: 75%; }
  .grid-col-10-md {
    display: block;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .grid-col-11-md {
    display: block;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .grid-col-12-md {
    display: block;
    flex: 0 0 100%;
    max-width: 100%; }
  .grid-col-flex-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .grid-col-auto-md {
    flex: 0 0 auto;
    width: auto;
    max-width: none; } }

@media only screen and (min-width: 992px) {
  .grid-col-1-lg {
    display: block;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .grid-col-2-lg {
    display: block;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .grid-col-3-lg {
    display: block;
    flex: 0 0 25%;
    max-width: 25%; }
  .grid-col-4-lg {
    display: block;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .grid-col-5-lg {
    display: block;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .grid-col-6-lg {
    display: block;
    flex: 0 0 50%;
    max-width: 50%; }
  .grid-col-7-lg {
    display: block;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .grid-col-8-lg {
    display: block;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .grid-col-9-lg {
    display: block;
    flex: 0 0 75%;
    max-width: 75%; }
  .grid-col-10-lg {
    display: block;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .grid-col-11-lg {
    display: block;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .grid-col-12-lg {
    display: block;
    flex: 0 0 100%;
    max-width: 100%; }
  .grid-col-flex-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .grid-col-auto-lg {
    flex: 0 0 auto;
    width: auto;
    max-width: none; } }

@media only screen and (min-width: 1200px) {
  .grid-col-1-xl {
    display: block;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .grid-col-2-xl {
    display: block;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .grid-col-3-xl {
    display: block;
    flex: 0 0 25%;
    max-width: 25%; }
  .grid-col-4-xl {
    display: block;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .grid-col-5-xl {
    display: block;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .grid-col-6-xl {
    display: block;
    flex: 0 0 50%;
    max-width: 50%; }
  .grid-col-7-xl {
    display: block;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .grid-col-8-xl {
    display: block;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .grid-col-9-xl {
    display: block;
    flex: 0 0 75%;
    max-width: 75%; }
  .grid-col-10-xl {
    display: block;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .grid-col-11-xl {
    display: block;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .grid-col-12-xl {
    display: block;
    flex: 0 0 100%;
    max-width: 100%; }
  .grid-col-flex-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .grid-col-auto-xl {
    flex: 0 0 auto;
    width: auto;
    max-width: none; } }

.hidden {
  display: none !important; }
  @media only screen and (max-width: 575px) {
    .hidden-xs {
      display: none !important; } }
  @media only screen and (min-width: 576px) {
    .hidden-sm {
      display: none !important; } }
  @media only screen and (min-width: 768px) {
    .hidden-md {
      display: none !important; } }
  @media only screen and (min-width: 992px) {
    .hidden-lg {
      display: none !important; } }
  @media only screen and (min-width: 1200px) {
    .hidden-xl {
      display: none !important; } }
  @media only screen and (max-width: 575px) {
    .hidden-before-sm {
      display: none !important; } }
  @media only screen and (max-width: 767px) {
    .hidden-before-md {
      display: none !important; } }
  @media only screen and (max-width: 991px) {
    .hidden-before-lg, .about-video-section__image {
      display: none !important; } }
  @media only screen and (max-width: 1199px) {
    .hidden-before-xl {
      display: none !important; } }

.fullwidth-wrapper {
  position: relative;
  width: 100%; }

.wrapper--without-offset, .page-content-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.wrapper--extra-small {
  max-width: 768px !important; }

@media only screen and (min-width: 992px) {
  .wrapper--small {
    max-width: 900px !important; } }

@media only screen and (min-width: 1440px) {
  .wrapper--large {
    max-width: 1440px; } }

.grid-container--nowrap {
  flex-wrap: nowrap !important; }

.grid-container--no-gutter {
  margin-left: 0 !important;
  margin-right: 0 !important; }
  .grid-container--no-gutter > .grid-col {
    padding-left: 0 !important;
    padding-right: 0 !important; }

.grid-container--large-gutter {
  margin-left: -18px !important;
  margin-right: -18px !important; }
  .grid-container--large-gutter > .grid-col {
    padding-left: 18px !important;
    padding-right: 18px !important; }
    .grid-container--large-gutter > .grid-col--v-gutter {
      padding-top: 18px;
      padding-bottom: 18px; }

.grid-container--small-gutter {
  margin-left: -7px !important;
  margin-right: -7px !important; }
  .grid-container--small-gutter > .grid-col {
    padding-left: 7px !important;
    padding-right: 7px !important; }
    .grid-container--small-gutter > .grid-col--v-gutter {
      padding-top: 7px;
      padding-bottom: 7px; }

.grid-col--v-gutter {
  padding-top: 12px;
  padding-bottom: 12px; }

.grid-col--no-gutter {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.vertical-grid-container {
  display: flex;
  flex-direction: column; }

/* base */
html,
body {
  margin: 0;
  padding: 0;
  background-color: #0e0e0e;
  color: #fff;
  font-family: "PT Sans", sans-serif;
  font-weight: normal;
  font-size: 16px; }
  @media only screen and (min-width: 1440px) {
    html,
    body {
      font-size: 18px; } }

body {
  min-width: 320px;
  overflow-x: hidden;
  overflow-y: scroll; }

.burger-is-active .scroll-to-top-btn,
.white-bg .scroll-to-top-btn {
  color: #0e0e0e; }
  .burger-is-active .scroll-to-top-btn__line,
  .white-bg .scroll-to-top-btn__line {
    background-color: rgba(14, 14, 14, 0.17); }
    .burger-is-active .scroll-to-top-btn__line::after,
    .white-bg .scroll-to-top-btn__line::after {
      background-color: #0e0e0e; }

.burger-is-active .side-info-link,
.white-bg .side-info-link {
  color: #0e0e0e; }

::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

::-webkit-scrollbar-button {
  width: 5px;
  height: 5px; }

::-webkit-scrollbar-thumb {
  background: #fefcff;
  border: 0px none #ffffff;
  border-radius: 0px; }

::-webkit-scrollbar-thumb:hover {
  background: #aaa; }

::-webkit-scrollbar-thumb:active {
  background: #ffffff; }

::-webkit-scrollbar-track {
  background: #000000;
  border: 0px none #ffffff;
  border-radius: 50px; }

::-webkit-scrollbar-track:hover {
  background: #000000; }

::-webkit-scrollbar-track:active {
  background: #000000; }

::-webkit-scrollbar-corner {
  background: transparent; }

p {
  font-family: "PT Sans", sans-serif;
  line-height: 1.5;
  margin-top: 1em;
  margin-bottom: 1em; }

/* headinds */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1em;
  margin-bottom: 1em;
  font-family: "Gilroy", sans-serif;
  letter-spacing: 0.16em;
  font-weight: 800;
  line-height: 1.25; }

/* controls */
a,
input,
textarea,
button,
select,
option,
label {
  font-family: "PT Sans", sans-serif; }

button,
input,
optgroup,
select,
textarea {
  line-height: 1.25;
  border-radius: 0; }

a {
  text-decoration: none;
  color: #ff478c;
  transition: color 0.24s ease; }

.color-accent {
  color: #ff478c; }

.text-align-left {
  text-align: left; }

.text-align-center {
  text-align: center; }

.text-align-right {
  text-align: right; }

.scrolling-disabled,
.overflow-hidden {
  overflow: hidden; }

.p-relative {
  position: relative; }

.page {
  position: relative;
  z-index: 1; }
  .page__bg {
    z-index: -1;
    will-change: transform;
    transform: translateZ(0); }
    .page__bg--moon {
      background-image: url("images/home/moon-bg.png"); }
  .page--fullscreen {
    width: 100%;
    min-height: 100vh; }
  .page-left-page-btn, .page-right-page-btn {
    display: none;
    position: absolute;
    top: 46%;
    width: 7%;
    width: 5vw;
    width: calc(5vw + 45px);
    height: 200px;
    margin-top: -75px;
    color: #fff; }
    @media only screen and (min-width: 1200px) {
      .page-left-page-btn, .page-right-page-btn {
        display: block; } }
    @media only screen and (min-width: 1441px) {
      .page-left-page-btn, .page-right-page-btn {
        width: 8%;
        width: 6vw;
        width: calc(6vw + 44px); } }
    .page-left-page-btn:hover::before, .page-left-page-btn:hover::after, .page-right-page-btn:hover::before, .page-right-page-btn:hover::after {
      transform: scaleX(1.75); }
    .page-left-page-btn::before, .page-left-page-btn::after, .page-right-page-btn::before, .page-right-page-btn::after {
      content: "";
      position: absolute;
      top: 50%;
      width: 35%;
      height: 1px;
      transition: transform 0.48s cubic-bezier(0.2, 0.5, 0.3, 1); }
    .page-left-page-btn::before, .page-right-page-btn::before {
      background-color: rgba(255, 255, 255, 0.17);
      transform: scaleX(1); }
    .page-left-page-btn::after, .page-right-page-btn::after {
      background-color: #fff;
      transform: scaleX(0); }
  .page-left-page-btn__text, .page-right-page-btn__text {
    position: absolute;
    top: 50%;
    height: 1rem;
    line-height: 1rem;
    white-space: nowrap;
    transform-origin: 50% 50%; }
  .page-left-page-btn {
    left: 0; }
    .page-left-page-btn::before, .page-left-page-btn::after {
      transform-origin: 0 0; }
    .page-left-page-btn:hover .page-left-page-btn__text {
      transform: translate3d(50%, -50%, 0) rotate(-90deg); }
    .page-left-page-btn__text {
      right: 0;
      transform: translate3d(25%, -50%, 0) rotate(-90deg);
      transition: transform 0.48s cubic-bezier(0.2, 0.5, 0.3, 1); }
  .page-right-page-btn {
    right: 0; }
    .page-right-page-btn::before, .page-right-page-btn::after {
      right: 0;
      transform-origin: 100% 0; }
    .page-right-page-btn:hover .page-right-page-btn__text {
      transform: translate3d(-50%, -50%, 0) rotate(90deg); }
    .page-right-page-btn__text {
      left: 0;
      transform: translate3d(-25%, -50%, 0) rotate(90deg);
      transition: transform 0.48s cubic-bezier(0.2, 0.5, 0.3, 1); }
  .page-transition-appear {
    opacity: 0; }
    .page-transition-appear-active {
      opacity: 1;
      transition: all 0.5s ease-in-out; }
  .page__top-name {
    position: relative;
    z-index: 2;
    font-family: "PT Sans", sans-serif;
    font-size: 1rem;
    font-weight: normal;
    margin: 0 0 1rem 0;
    width: 50%;
    min-height: 80px; }
    @media only screen and (max-width: 1199px) {
      .page__top-name {
        padding-left: 100px; } }
    @media only screen and (max-width: 767px) {
      .page__top-name {
        padding-left: 80px;
        min-height: 60px; } }
    @media only screen and (max-width: 575px) {
      .page__top-name {
        padding: 92px 0 0 0; } }
  .page-content-wrapper {
    width: 100%; }
    @media only screen and (min-width: 1200px) {
      .page-content-wrapper {
        width: 64%; } }

.reveal,
.reveal .reveal-container {
  display: block;
  overflow: hidden; }

.reveal.active .reveal-item {
  transform: scale(1) translateY(0%) translateZ(0); }

.reveal .reveal-item {
  display: block;
  transform: scale(0.9) translateY(100%) translateZ(0);
  transform-origin: 50% 100%;
  transition: transform 1s cubic-bezier(0.33, 0.51, 0.13, 1), opacity 0.48s cubic-bezier(0.13, 0.33, 0.55, 1); }

.words-appear.active .words-appear__word {
  opacity: 1; }

.words-appear .words-appear__word {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 1.36s;
  will-change: opacity;
  transform: translateZ(0); }

.primary-button--black, .primary-button--white, .primary-checkbox-btn__label, .primary-button--white-alt {
  font-family: "PT Sans", sans-serif;
  text-align: center;
  outline: none;
  transition: 0.32s; }

.primary-button--black, .primary-button--white, .primary-checkbox-btn__label, .primary-button--white-alt {
  z-index: 1;
  position: relative;
  font-weight: bold;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  transform: scale(1);
  transition: color .36s ease, transform .36s ease; }
  .primary-button--black::before, .primary-button--white::before, .primary-checkbox-btn__label::before, .primary-button--white-alt::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    z-index: -1;
    opacity: 0;
    transform: scale(0.875);
    transform-origin: 50%;
    transition: opacity 0.36s cubic-bezier(0.2, 0.5, 0.3, 1), transform 0.36s cubic-bezier(0.2, 0.5, 0.3, 1); }
  .primary-button--black:active, .primary-button--white:active, .primary-checkbox-btn__label:active, .primary-button--white-alt:active {
    transform: scale(0.925); }
  .primary-button--black:hover::before, .primary-button--white:hover::before, .primary-checkbox-btn__label:hover::before, .primary-button--white-alt:hover::before {
    opacity: 1;
    transform: scale(1); }

.primary-button, .primary-checkbox-btn__label {
  cursor: pointer;
  appearance: none;
  display: inline-block;
  text-decoration: none;
  background: none;
  border: none; }
  .primary-button--medium {
    font-size: 12px;
    padding: 0.75rem 1.5rem; }
    @media only screen and (min-width: 768px) {
      .primary-button--medium {
        padding: 0.75rem 2.45rem; } }
  .primary-button--black {
    border: 2px solid #0e0e0e;
    color: #0e0e0e; }
    .primary-button--black::before {
      background: #0e0e0e; }
    .primary-button--black:hover {
      color: #fff; }
  .primary-button--white, .primary-checkbox-btn__label {
    border: 2px solid #fff;
    color: #fff; }
    .primary-button--white::before, .primary-checkbox-btn__label::before {
      background: #fff; }
    .primary-button--white:hover, .primary-checkbox-btn__label:hover {
      color: #0e0e0e; }
  .primary-button--white-alt {
    border: 2px solid #fff;
    color: #0e0e0e; }
    .primary-button--white-alt::before {
      background: #fff;
      opacity: 1;
      transform: scale(1); }
    .primary-button--white-alt:hover {
      color: #fff; }
    .primary-button--white-alt:hover::before {
      opacity: 0;
      transform: scale(0.875); }
  .primary-button--fullwidth {
    display: block !important;
    width: 100%; }

.lines-bg {
  display: block;
  padding-top: 0;
  padding-bottom: 0; }
  .lines-bg--default .lines-bg__line:nth-child(2) {
    left: 50%; }
    @media only screen and (min-width: 992px) {
      .lines-bg--default .lines-bg__line:nth-child(2) {
        left: 33.33333%; } }
    @media only screen and (max-width: 575px) {
      .lines-bg--default .lines-bg__line:nth-child(2) {
        display: none; } }
  .lines-bg--default .lines-bg__line:nth-child(3) {
    right: 33.33333%; }
    @media only screen and (max-width: 991px) {
      .lines-bg--default .lines-bg__line:nth-child(3) {
        display: none; } }
  .lines-bg--about-page .lines-bg__line:nth-child(2) {
    left: 50%; }
    @media only screen and (min-width: 768px) {
      .lines-bg--about-page .lines-bg__line:nth-child(2) {
        left: 33.33333%; } }
    @media only screen and (max-width: 575px) {
      .lines-bg--about-page .lines-bg__line:nth-child(2) {
        display: none; } }
  .lines-bg--about-page .lines-bg__line:nth-child(3) {
    right: 33.33333%; }
    @media only screen and (max-width: 767px) {
      .lines-bg--about-page .lines-bg__line:nth-child(3) {
        display: none; } }
  .lines-bg__wrapper {
    position: relative;
    height: 100vh; }
  .lines-bg__line {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    background-color: #444; }
    .lines-bg__line:nth-child(1) {
      left: -1px; }
    .lines-bg__line:nth-child(4) {
      right: -1px; }

.default-link {
  color: #fff;
  transition: color .36s ease; }
  .default-link:hover {
    color: #ff478c; }

.text-glitch::before, .text-glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.text-glitch {
  position: relative; }
  .text-glitch:hover::before {
    animation: text-glitch 1s linear infinite alternate-reverse; }
  .text-glitch:hover::after {
    animation: text-glitch 1s linear 1s infinite alternate-reverse; }
  .text-glitch::before {
    left: 2px;
    text-shadow: -2px 0 #ff478c;
    clip: rect(0, 0, 0, 0);
    animation: text-glitch-2 5s linear infinite alternate-reverse; }
  .text-glitch::after {
    left: -2px;
    clip: rect(0, 0, 0, 0);
    text-shadow: -2px 0 #fff, 2px 2px #ff478c;
    animation: text-glitch-2 3s linear 0.75s infinite alternate-reverse; }

@keyframes text-glitch {
  0% {
    clip: rect(81px, 9999px, 68px, 0); }
  5% {
    clip: rect(66px, 9999px, 73px, 0); }
  10% {
    clip: rect(61px, 9999px, 66px, 0); }
  15% {
    clip: rect(70px, 9999px, 37px, 0); }
  20% {
    clip: rect(69px, 9999px, 70px, 0); }
  25% {
    clip: rect(5px, 9999px, 95px, 0); }
  30% {
    clip: rect(61px, 9999px, 55px, 0); }
  35% {
    clip: rect(87px, 9999px, 27px, 0); }
  40% {
    clip: rect(32px, 9999px, 57px, 0); }
  45% {
    clip: rect(8px, 9999px, 17px, 0); }
  50% {
    clip: rect(73px, 9999px, 30px, 0); }
  55% {
    clip: rect(55px, 9999px, 24px, 0); }
  60% {
    clip: rect(7px, 9999px, 59px, 0); }
  65% {
    clip: rect(80px, 9999px, 84px, 0); }
  70% {
    clip: rect(70px, 9999px, 65px, 0); }
  75% {
    clip: rect(90px, 9999px, 13px, 0); }
  80% {
    clip: rect(22px, 9999px, 47px, 0); }
  85% {
    clip: rect(9px, 9999px, 20px, 0); }
  90% {
    clip: rect(79px, 9999px, 78px, 0); }
  95% {
    clip: rect(53px, 9999px, 40px, 0); }
  100% {
    clip: rect(97px, 9999px, 4px, 0); } }

@keyframes text-glitch-2 {
  0% {
    clip: rect(0, 0, 0, 0); }
  2.5% {
    clip: rect(99px, 9999px, 51px, 0); }
  5% {
    clip: rect(0, 0, 0, 0); }
  20% {
    clip: rect(0, 0, 0, 0); }
  22.5% {
    clip: rect(63px, 9999px, 29px, 0); }
  25% {
    clip: rect(0, 0, 0, 0); }
  40% {
    clip: rect(0, 0, 0, 0); }
  42.5% {
    clip: rect(98px, 9999px, 79px, 0); }
  45% {
    clip: rect(0, 0, 0, 0); }
  60% {
    clip: rect(0, 0, 0, 0); }
  62.5% {
    clip: rect(15px, 9999px, 23px, 0); }
  65% {
    clip: rect(0, 0, 0, 0); }
  80% {
    clip: rect(0, 0, 0, 0); }
  82.5% {
    clip: rect(59px, 9999px, 28px, 0); }
  85% {
    clip: rect(0, 0, 0, 0); }
  100% {
    clip: rect(0, 0, 0, 0); }
  102.5% {
    clip: rect(30px, 9999px, 20px, 0); }
  105% {
    clip: rect(0, 0, 0, 0); } }

.icon-text {
  display: block;
  position: relative; }
  .icon-text__icon {
    position: absolute;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    font-size: 1rem;
    top: 50%;
    left: 0;
    margin-top: -0.5rem; }
  .icon-text__text {
    padding-left: 2.24rem; }

.burger-btn {
  cursor: pointer;
  display: block;
  color: #fff;
  position: relative;
  transition: color 0.24s ease; }
  .burger-btn:hover {
    color: #ff478c !important; }
  .burger-btn.active {
    color: #0e0e0e; }
  .burger-btn.active .burger-btn-text__container {
    transform: translateY(-1rem); }
  .burger-btn-text {
    text-transform: uppercase;
    text-align: right;
    letter-spacing: 2px;
    font-size: 1rem;
    line-height: 1rem;
    height: 1rem;
    overflow: hidden; }
    .burger-btn-text__close {
      position: relative; }
      @media only screen and (min-width: 768px) {
        .burger-btn-text__close {
          padding-left: 70px; } }
      .burger-btn-text__close::before, .burger-btn-text__close::after {
        content: "";
        display: none;
        position: absolute;
        top: 50%;
        left: 0;
        width: 20px;
        height: 2px;
        margin-top: -1px;
        background-color: #0e0e0e; }
        @media only screen and (min-width: 768px) {
          .burger-btn-text__close::before, .burger-btn-text__close::after {
            display: block; } }
      .burger-btn-text__close::before {
        transform: rotate(45deg); }
      .burger-btn-text__close::after {
        transform: rotate(-45deg); }
    .burger-btn-text__container {
      transform: translateY(0);
      transition: transform 0.36s ease; }

.burger-content {
  display: block;
  background: #fff;
  z-index: -1;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  visibility: hidden;
  will-change: transform;
  transform: translate3d(0, -100%, 0);
  transition: visibility 0.6s, transform 0.6s ease; }
  .burger-content.active {
    visibility: visible;
    transform: translate3d(0, 0, 0); }
  .burger-content.active .burger-content__wrapper {
    transform: translate3d(0, 0, 0); }
  .burger-content__wrapper {
    position: relative;
    overflow: hidden;
    height: 100vh;
    will-change: transform;
    transform: translate3d(0, 85%, 0);
    transition: transform .6s ease; }
  .burger-content-menu {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 120px;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf; }
    @media only screen and (min-width: 768px) {
      .burger-content-menu {
        padding-top: 16vw;
        padding-top: calc(6vw + 110px);
        width: 35%; } }
    @media only screen and (min-width: 1200px) {
      .burger-content-menu {
        padding-top: 6vw; } }
    .burger-content-menu li {
      position: relative;
      font-weight: bold;
      display: block;
      padding: 0;
      margin: 0;
      background: #fff; }
      .burger-content-menu li a {
        display: block;
        position: relative;
        z-index: 1;
        color: #131416; }
        .burger-content-menu li a::after {
          content: "";
          display: block;
          position: absolute;
          z-index: 0;
          bottom: 2rem;
          left: 0;
          right: 0;
          width: 100%;
          height: 3px;
          background: #ff478c;
          pointer-events: none;
          visibility: hidden;
          transform: scaleX(0);
          transform-origin: 0 50%;
          transition: 0.5s ease; }
        .burger-content-menu li a:hover::after {
          visibility: visible;
          transform: scaleX(1); }
        .burger-content-menu li a span {
          display: inline-block;
          position: relative;
          z-index: 1;
          line-height: 1;
          background: #fff;
          padding: 1.75rem 1.75rem 1.75rem 0; }
        .burger-content-menu li a:hover {
          color: #ff478c; }
  .burger-content-image {
    position: absolute;
    display: none;
    width: 65%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    padding-top: 36px;
    border-right: 1px solid #dfdfdf;
    background-image: url("images/burger-menu-bg.png"); }
    @media only screen and (min-width: 768px) {
      .burger-content-image {
        display: block; } }

.burger-is-active .header {
  position: fixed; }

.burger-is-active .logo svg:nth-child(1) {
  fill: #0e0e0e; }

.burger-is-active .language-switcher__item:not(.active) {
  color: #0e0e0e; }

.language-switcher__item {
  cursor: pointer;
  color: #fff;
  margin-left: 0.875rem;
  transition: color .36s ease; }
  .language-switcher__item.active {
    color: #ff478c; }
  .language-switcher__item:hover {
    color: #ff478c !important; }

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: 0;
  z-index: 1000; }
  .header__logo, .header__nav {
    position: absolute;
    top: 0; }
  .header__logo {
    left: 0; }
  .header__nav {
    right: 0; }
  .header__line {
    display: block;
    width: 200px;
    height: 4px;
    background-color: #fff; }

.padding-from-header {
  padding-top: 60px; }
  @media only screen and (min-width: 768px) {
    .padding-from-header {
      padding-top: 80px; } }

.margin-from-header {
  margin-top: 60px; }
  @media only screen and (min-width: 768px) {
    .margin-from-header {
      margin-top: 80px; } }

.header-height {
  height: 60px; }
  @media only screen and (min-width: 768px) {
    .header-height {
      height: 80px; } }

@keyframes loading-screen-spin {
  0% {
    transform: translateX(-100%); }
  50% {
    transform: translateX(0); }
  100% {
    transform: translateX(100%); } }

.loading-screen {
  z-index: 10000;
  background-color: #0e0e0e;
  overflow: hidden;
  pointer-events: none;
  opacity: 1;
  transition: 0.4s; }
  .loading-screen--hidden {
    opacity: 0; }
  .loading-screen.fade-enter {
    opacity: 0;
    transition: opacity 0.64s cubic-bezier(0.2, 0.5, 0.3, 1);
    will-change: opacity; }
    .loading-screen.fade-enter-active {
      opacity: 1; }
  .loading-screen.fade-leave {
    opacity: 1;
    transition: opacity 0.64s cubic-bezier(0.2, 0.5, 0.3, 1);
    will-change: opacity; }
    .loading-screen.fade-leave-active {
      opacity: 0; }
  .loading-screen__spin {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 4px;
    margin-top: -2px;
    margin-left: -100px;
    background-color: rgba(255, 255, 255, 0.12);
    overflow: hidden; }
    .loading-screen__spin::before {
      content: "";
      background: #fff;
      animation: loading-screen-spin 1.24s infinite ease; }

.logo {
  display: block;
  cursor: pointer;
  position: relative;
  width: 64px;
  height: 80px; }

@keyframes logo-glitch-anim-1 {
  0% {
    clip: rect(65px, 64px, 31px, 0);
    opacity: 0; }
  0% {
    clip: rect(65px, 64px, 31px, 0);
    opacity: 1; }
  0% {
    clip: rect(57px, 64px, 35px, 0);
    opacity: 1; }
  0% {
    clip: rect(57px, 64px, 35px, 0);
    opacity: 0; }
  6.78571% {
    clip: rect(54px, 64px, 6px, 0);
    opacity: 0; }
  7.14286% {
    clip: rect(54px, 64px, 6px, 0);
    opacity: 1; }
  7.5% {
    clip: rect(13px, 64px, 24px, 0);
    opacity: 1; }
  7.85714% {
    clip: rect(13px, 64px, 24px, 0);
    opacity: 0; }
  13.57143% {
    clip: rect(22px, 64px, 22px, 0);
    opacity: 0; }
  14.28571% {
    clip: rect(22px, 64px, 22px, 0);
    opacity: 1; }
  15% {
    clip: rect(42px, 64px, 78px, 0);
    opacity: 1; }
  15.71429% {
    clip: rect(42px, 64px, 78px, 0);
    opacity: 0; }
  20.35714% {
    clip: rect(13px, 64px, 9px, 0);
    opacity: 0; }
  21.42857% {
    clip: rect(13px, 64px, 9px, 0);
    opacity: 1; }
  22.5% {
    clip: rect(66px, 64px, 69px, 0);
    opacity: 1; }
  23.57143% {
    clip: rect(66px, 64px, 69px, 0);
    opacity: 0; }
  27.14286% {
    clip: rect(73px, 64px, 82px, 0);
    opacity: 0; }
  28.57143% {
    clip: rect(73px, 64px, 82px, 0);
    opacity: 1; }
  30% {
    clip: rect(64px, 64px, 13px, 0);
    opacity: 1; }
  31.42857% {
    clip: rect(64px, 64px, 13px, 0);
    opacity: 0; }
  33.92857% {
    clip: rect(26px, 64px, 21px, 0);
    opacity: 0; }
  35.71429% {
    clip: rect(26px, 64px, 21px, 0);
    opacity: 1; }
  37.5% {
    clip: rect(24px, 64px, 72px, 0);
    opacity: 1; }
  39.28571% {
    clip: rect(24px, 64px, 72px, 0);
    opacity: 0; }
  40.71429% {
    clip: rect(58px, 64px, 81px, 0);
    opacity: 0; }
  42.85714% {
    clip: rect(58px, 64px, 81px, 0);
    opacity: 1; }
  45% {
    clip: rect(5px, 64px, 27px, 0);
    opacity: 1; }
  47.14286% {
    clip: rect(5px, 64px, 27px, 0);
    opacity: 0; }
  47.5% {
    clip: rect(1px, 64px, 17px, 0);
    opacity: 0; }
  50% {
    clip: rect(1px, 64px, 17px, 0);
    opacity: 1; }
  52.5% {
    clip: rect(21px, 64px, 72px, 0);
    opacity: 1; }
  55% {
    clip: rect(21px, 64px, 72px, 0);
    opacity: 0; }
  54.28571% {
    clip: rect(28px, 64px, 37px, 0);
    opacity: 0; }
  57.14286% {
    clip: rect(28px, 64px, 37px, 0);
    opacity: 1; }
  60% {
    clip: rect(58px, 64px, 70px, 0);
    opacity: 1; }
  62.85714% {
    clip: rect(58px, 64px, 70px, 0);
    opacity: 0; }
  61.07143% {
    clip: rect(45px, 64px, 30px, 0);
    opacity: 0; }
  64.28571% {
    clip: rect(45px, 64px, 30px, 0);
    opacity: 1; }
  67.5% {
    clip: rect(10px, 64px, 52px, 0);
    opacity: 1; }
  70.71429% {
    clip: rect(10px, 64px, 52px, 0);
    opacity: 0; }
  67.85714% {
    clip: rect(26px, 64px, 31px, 0);
    opacity: 0; }
  71.42857% {
    clip: rect(26px, 64px, 31px, 0);
    opacity: 1; }
  75% {
    clip: rect(75px, 64px, 53px, 0);
    opacity: 1; }
  78.57143% {
    clip: rect(75px, 64px, 53px, 0);
    opacity: 0; }
  74.64286% {
    clip: rect(13px, 64px, 17px, 0);
    opacity: 0; }
  78.57143% {
    clip: rect(13px, 64px, 17px, 0);
    opacity: 1; }
  82.5% {
    clip: rect(31px, 64px, 20px, 0);
    opacity: 1; }
  86.42857% {
    clip: rect(31px, 64px, 20px, 0);
    opacity: 0; }
  81.42857% {
    clip: rect(57px, 64px, 40px, 0);
    opacity: 0; }
  85.71429% {
    clip: rect(57px, 64px, 40px, 0);
    opacity: 1; }
  90% {
    clip: rect(16px, 64px, 16px, 0);
    opacity: 1; }
  94.28571% {
    clip: rect(16px, 64px, 16px, 0);
    opacity: 0; }
  88.21429% {
    clip: rect(15px, 64px, 9px, 0);
    opacity: 0; }
  92.85714% {
    clip: rect(15px, 64px, 9px, 0);
    opacity: 1; }
  97.5% {
    clip: rect(42px, 64px, 20px, 0);
    opacity: 1; }
  102.14286% {
    clip: rect(42px, 64px, 20px, 0);
    opacity: 0; }
  95% {
    clip: rect(39px, 64px, 72px, 0);
    opacity: 0; }
  100% {
    clip: rect(39px, 64px, 72px, 0);
    opacity: 1; }
  105% {
    clip: rect(6px, 64px, 34px, 0);
    opacity: 1; }
  110% {
    clip: rect(6px, 64px, 34px, 0);
    opacity: 0; } }

@keyframes logo-glitch-anim-1-long {
  0% {
    clip: rect(75px, 64px, 78px, 0);
    opacity: 0; }
  0% {
    clip: rect(75px, 64px, 78px, 0);
    opacity: 1; }
  0% {
    clip: rect(71px, 64px, 81px, 0);
    opacity: 1; }
  0% {
    clip: rect(71px, 64px, 81px, 0);
    opacity: 0; }
  24.75% {
    clip: rect(50px, 64px, 50px, 0);
    opacity: 0; }
  25% {
    clip: rect(50px, 64px, 50px, 0);
    opacity: 1; }
  25.225% {
    clip: rect(57px, 64px, 22px, 0);
    opacity: 1; }
  25.45% {
    clip: rect(57px, 64px, 22px, 0);
    opacity: 0; }
  49.5% {
    clip: rect(33px, 64px, 33px, 0);
    opacity: 0; }
  50% {
    clip: rect(33px, 64px, 33px, 0);
    opacity: 1; }
  50.45% {
    clip: rect(65px, 64px, 82px, 0);
    opacity: 1; }
  50.9% {
    clip: rect(65px, 64px, 82px, 0);
    opacity: 0; }
  74.25% {
    clip: rect(53px, 64px, 62px, 0);
    opacity: 0; }
  75% {
    clip: rect(53px, 64px, 62px, 0);
    opacity: 1; }
  75.675% {
    clip: rect(10px, 64px, 35px, 0);
    opacity: 1; }
  76.35% {
    clip: rect(10px, 64px, 35px, 0);
    opacity: 0; }
  99% {
    clip: rect(55px, 64px, 63px, 0);
    opacity: 0; }
  100% {
    clip: rect(55px, 64px, 63px, 0);
    opacity: 1; }
  100.9% {
    clip: rect(33px, 64px, 24px, 0);
    opacity: 1; }
  101.8% {
    clip: rect(33px, 64px, 24px, 0);
    opacity: 0; } }

@keyframes logo-glitch-anim-2 {
  0% {
    clip: rect(70px, 64px, 12px, 0);
    opacity: 0; }
  0% {
    clip: rect(70px, 64px, 12px, 0);
    opacity: 1; }
  0% {
    clip: rect(55px, 64px, 68px, 0);
    opacity: 1; }
  0% {
    clip: rect(55px, 64px, 68px, 0);
    opacity: 0; }
  6.78571% {
    clip: rect(50px, 64px, 19px, 0);
    opacity: 0; }
  7.14286% {
    clip: rect(50px, 64px, 19px, 0);
    opacity: 1; }
  7.5% {
    clip: rect(53px, 64px, 42px, 0);
    opacity: 1; }
  7.85714% {
    clip: rect(53px, 64px, 42px, 0);
    opacity: 0; }
  13.57143% {
    clip: rect(41px, 64px, 62px, 0);
    opacity: 0; }
  14.28571% {
    clip: rect(41px, 64px, 62px, 0);
    opacity: 1; }
  15% {
    clip: rect(41px, 64px, 20px, 0);
    opacity: 1; }
  15.71429% {
    clip: rect(41px, 64px, 20px, 0);
    opacity: 0; }
  20.35714% {
    clip: rect(10px, 64px, 83px, 0);
    opacity: 0; }
  21.42857% {
    clip: rect(10px, 64px, 83px, 0);
    opacity: 1; }
  22.5% {
    clip: rect(61px, 64px, 46px, 0);
    opacity: 1; }
  23.57143% {
    clip: rect(61px, 64px, 46px, 0);
    opacity: 0; }
  27.14286% {
    clip: rect(59px, 64px, 9px, 0);
    opacity: 0; }
  28.57143% {
    clip: rect(59px, 64px, 9px, 0);
    opacity: 1; }
  30% {
    clip: rect(40px, 64px, 51px, 0);
    opacity: 1; }
  31.42857% {
    clip: rect(40px, 64px, 51px, 0);
    opacity: 0; }
  33.92857% {
    clip: rect(31px, 64px, 16px, 0);
    opacity: 0; }
  35.71429% {
    clip: rect(31px, 64px, 16px, 0);
    opacity: 1; }
  37.5% {
    clip: rect(35px, 64px, 12px, 0);
    opacity: 1; }
  39.28571% {
    clip: rect(35px, 64px, 12px, 0);
    opacity: 0; }
  40.71429% {
    clip: rect(52px, 64px, 71px, 0);
    opacity: 0; }
  42.85714% {
    clip: rect(52px, 64px, 71px, 0);
    opacity: 1; }
  45% {
    clip: rect(48px, 64px, 5px, 0);
    opacity: 1; }
  47.14286% {
    clip: rect(48px, 64px, 5px, 0);
    opacity: 0; }
  47.5% {
    clip: rect(38px, 64px, 44px, 0);
    opacity: 0; }
  50% {
    clip: rect(38px, 64px, 44px, 0);
    opacity: 1; }
  52.5% {
    clip: rect(38px, 64px, 52px, 0);
    opacity: 1; }
  55% {
    clip: rect(38px, 64px, 52px, 0);
    opacity: 0; }
  54.28571% {
    clip: rect(15px, 64px, 28px, 0);
    opacity: 0; }
  57.14286% {
    clip: rect(15px, 64px, 28px, 0);
    opacity: 1; }
  60% {
    clip: rect(36px, 64px, 37px, 0);
    opacity: 1; }
  62.85714% {
    clip: rect(36px, 64px, 37px, 0);
    opacity: 0; }
  61.07143% {
    clip: rect(20px, 64px, 48px, 0);
    opacity: 0; }
  64.28571% {
    clip: rect(20px, 64px, 48px, 0);
    opacity: 1; }
  67.5% {
    clip: rect(48px, 64px, 59px, 0);
    opacity: 1; }
  70.71429% {
    clip: rect(48px, 64px, 59px, 0);
    opacity: 0; }
  67.85714% {
    clip: rect(13px, 64px, 45px, 0);
    opacity: 0; }
  71.42857% {
    clip: rect(13px, 64px, 45px, 0);
    opacity: 1; }
  75% {
    clip: rect(68px, 64px, 76px, 0);
    opacity: 1; }
  78.57143% {
    clip: rect(68px, 64px, 76px, 0);
    opacity: 0; }
  74.64286% {
    clip: rect(22px, 64px, 56px, 0);
    opacity: 0; }
  78.57143% {
    clip: rect(22px, 64px, 56px, 0);
    opacity: 1; }
  82.5% {
    clip: rect(42px, 64px, 11px, 0);
    opacity: 1; }
  86.42857% {
    clip: rect(42px, 64px, 11px, 0);
    opacity: 0; }
  81.42857% {
    clip: rect(27px, 64px, 7px, 0);
    opacity: 0; }
  85.71429% {
    clip: rect(27px, 64px, 7px, 0);
    opacity: 1; }
  90% {
    clip: rect(71px, 64px, 52px, 0);
    opacity: 1; }
  94.28571% {
    clip: rect(71px, 64px, 52px, 0);
    opacity: 0; }
  88.21429% {
    clip: rect(21px, 64px, 55px, 0);
    opacity: 0; }
  92.85714% {
    clip: rect(21px, 64px, 55px, 0);
    opacity: 1; }
  97.5% {
    clip: rect(52px, 64px, 10px, 0);
    opacity: 1; }
  102.14286% {
    clip: rect(52px, 64px, 10px, 0);
    opacity: 0; }
  95% {
    clip: rect(13px, 64px, 28px, 0);
    opacity: 0; }
  100% {
    clip: rect(13px, 64px, 28px, 0);
    opacity: 1; }
  105% {
    clip: rect(42px, 64px, 47px, 0);
    opacity: 1; }
  110% {
    clip: rect(42px, 64px, 47px, 0);
    opacity: 0; } }

@keyframes logo-glitch-anim-2-long {
  0% {
    clip: rect(60px, 64px, 30px, 0);
    opacity: 0; }
  0% {
    clip: rect(60px, 64px, 30px, 0);
    opacity: 1; }
  0% {
    clip: rect(61px, 64px, 75px, 0);
    opacity: 1; }
  0% {
    clip: rect(61px, 64px, 75px, 0);
    opacity: 0; }
  24.75% {
    clip: rect(14px, 64px, 48px, 0);
    opacity: 0; }
  25% {
    clip: rect(14px, 64px, 48px, 0);
    opacity: 1; }
  25.225% {
    clip: rect(14px, 64px, 54px, 0);
    opacity: 1; }
  25.45% {
    clip: rect(14px, 64px, 54px, 0);
    opacity: 0; }
  49.5% {
    clip: rect(22px, 64px, 76px, 0);
    opacity: 0; }
  50% {
    clip: rect(22px, 64px, 76px, 0);
    opacity: 1; }
  50.45% {
    clip: rect(60px, 64px, 36px, 0);
    opacity: 1; }
  50.9% {
    clip: rect(60px, 64px, 36px, 0);
    opacity: 0; }
  74.25% {
    clip: rect(13px, 64px, 31px, 0);
    opacity: 0; }
  75% {
    clip: rect(13px, 64px, 31px, 0);
    opacity: 1; }
  75.675% {
    clip: rect(30px, 64px, 9px, 0);
    opacity: 1; }
  76.35% {
    clip: rect(30px, 64px, 9px, 0);
    opacity: 0; }
  99% {
    clip: rect(18px, 64px, 42px, 0);
    opacity: 0; }
  100% {
    clip: rect(18px, 64px, 42px, 0);
    opacity: 1; }
  100.9% {
    clip: rect(12px, 64px, 81px, 0);
    opacity: 1; }
  101.8% {
    clip: rect(12px, 64px, 81px, 0);
    opacity: 0; } }

@keyframes logo-glitch-anim-3 {
  0% {
    clip: rect(4px, 64px, 72px, 0);
    opacity: 0; }
  0% {
    clip: rect(4px, 64px, 72px, 0);
    opacity: 1; }
  0% {
    clip: rect(26px, 64px, 67px, 0);
    opacity: 1; }
  0% {
    clip: rect(26px, 64px, 67px, 0);
    opacity: 0; }
  6.78571% {
    clip: rect(70px, 64px, 41px, 0);
    opacity: 0; }
  7.14286% {
    clip: rect(70px, 64px, 41px, 0);
    opacity: 1; }
  7.5% {
    clip: rect(-2px, 64px, 24px, 0);
    opacity: 1; }
  7.85714% {
    clip: rect(-2px, 64px, 24px, 0);
    opacity: 0; }
  13.57143% {
    clip: rect(57px, 64px, 76px, 0);
    opacity: 0; }
  14.28571% {
    clip: rect(57px, 64px, 76px, 0);
    opacity: 1; }
  15% {
    clip: rect(61px, 64px, 54px, 0);
    opacity: 1; }
  15.71429% {
    clip: rect(61px, 64px, 54px, 0);
    opacity: 0; }
  20.35714% {
    clip: rect(28px, 64px, 70px, 0);
    opacity: 0; }
  21.42857% {
    clip: rect(28px, 64px, 70px, 0);
    opacity: 1; }
  22.5% {
    clip: rect(23px, 64px, 71px, 0);
    opacity: 1; }
  23.57143% {
    clip: rect(23px, 64px, 71px, 0);
    opacity: 0; }
  27.14286% {
    clip: rect(38px, 64px, 83px, 0);
    opacity: 0; }
  28.57143% {
    clip: rect(38px, 64px, 83px, 0);
    opacity: 1; }
  30% {
    clip: rect(43px, 64px, 6px, 0);
    opacity: 1; }
  31.42857% {
    clip: rect(43px, 64px, 6px, 0);
    opacity: 0; }
  33.92857% {
    clip: rect(66px, 64px, 46px, 0);
    opacity: 0; }
  35.71429% {
    clip: rect(66px, 64px, 46px, 0);
    opacity: 1; }
  37.5% {
    clip: rect(23px, 64px, 69px, 0);
    opacity: 1; }
  39.28571% {
    clip: rect(23px, 64px, 69px, 0);
    opacity: 0; }
  40.71429% {
    clip: rect(52px, 64px, 13px, 0);
    opacity: 0; }
  42.85714% {
    clip: rect(52px, 64px, 13px, 0);
    opacity: 1; }
  45% {
    clip: rect(2px, 64px, 20px, 0);
    opacity: 1; }
  47.14286% {
    clip: rect(2px, 64px, 20px, 0);
    opacity: 0; }
  47.5% {
    clip: rect(10px, 64px, 63px, 0);
    opacity: 0; }
  50% {
    clip: rect(10px, 64px, 63px, 0);
    opacity: 1; }
  52.5% {
    clip: rect(74px, 64px, 68px, 0);
    opacity: 1; }
  55% {
    clip: rect(74px, 64px, 68px, 0);
    opacity: 0; }
  54.28571% {
    clip: rect(7px, 64px, 59px, 0);
    opacity: 0; }
  57.14286% {
    clip: rect(7px, 64px, 59px, 0);
    opacity: 1; }
  60% {
    clip: rect(51px, 64px, 46px, 0);
    opacity: 1; }
  62.85714% {
    clip: rect(51px, 64px, 46px, 0);
    opacity: 0; }
  61.07143% {
    clip: rect(17px, 64px, 41px, 0);
    opacity: 0; }
  64.28571% {
    clip: rect(17px, 64px, 41px, 0);
    opacity: 1; }
  67.5% {
    clip: rect(9px, 64px, 70px, 0);
    opacity: 1; }
  70.71429% {
    clip: rect(9px, 64px, 70px, 0);
    opacity: 0; }
  67.85714% {
    clip: rect(15px, 64px, 43px, 0);
    opacity: 0; }
  71.42857% {
    clip: rect(15px, 64px, 43px, 0);
    opacity: 1; }
  75% {
    clip: rect(45px, 64px, 36px, 0);
    opacity: 1; }
  78.57143% {
    clip: rect(45px, 64px, 36px, 0);
    opacity: 0; }
  74.64286% {
    clip: rect(22px, 64px, 32px, 0);
    opacity: 0; }
  78.57143% {
    clip: rect(22px, 64px, 32px, 0);
    opacity: 1; }
  82.5% {
    clip: rect(30px, 64px, 44px, 0);
    opacity: 1; }
  86.42857% {
    clip: rect(30px, 64px, 44px, 0);
    opacity: 0; }
  81.42857% {
    clip: rect(10px, 64px, 40px, 0);
    opacity: 0; }
  85.71429% {
    clip: rect(10px, 64px, 40px, 0);
    opacity: 1; }
  90% {
    clip: rect(2px, 64px, 32px, 0);
    opacity: 1; }
  94.28571% {
    clip: rect(2px, 64px, 32px, 0);
    opacity: 0; }
  88.21429% {
    clip: rect(8px, 64px, 68px, 0);
    opacity: 0; }
  92.85714% {
    clip: rect(8px, 64px, 68px, 0);
    opacity: 1; }
  97.5% {
    clip: rect(55px, 64px, 45px, 0);
    opacity: 1; }
  102.14286% {
    clip: rect(55px, 64px, 45px, 0);
    opacity: 0; }
  95% {
    clip: rect(72px, 64px, 75px, 0);
    opacity: 0; }
  100% {
    clip: rect(72px, 64px, 75px, 0);
    opacity: 1; }
  105% {
    clip: rect(14px, 64px, 18px, 0);
    opacity: 1; }
  110% {
    clip: rect(14px, 64px, 18px, 0);
    opacity: 0; } }

@keyframes logo-glitch-anim-3-long {
  0% {
    clip: rect(73px, 64px, 79px, 0);
    opacity: 0; }
  0% {
    clip: rect(73px, 64px, 79px, 0);
    opacity: 1; }
  0% {
    clip: rect(56px, 64px, 75px, 0);
    opacity: 1; }
  0% {
    clip: rect(56px, 64px, 75px, 0);
    opacity: 0; }
  24.75% {
    clip: rect(22px, 64px, 44px, 0);
    opacity: 0; }
  25% {
    clip: rect(22px, 64px, 44px, 0);
    opacity: 1; }
  25.225% {
    clip: rect(61px, 64px, 60px, 0);
    opacity: 1; }
  25.45% {
    clip: rect(61px, 64px, 60px, 0);
    opacity: 0; }
  49.5% {
    clip: rect(14px, 64px, 6px, 0);
    opacity: 0; }
  50% {
    clip: rect(14px, 64px, 6px, 0);
    opacity: 1; }
  50.45% {
    clip: rect(44px, 64px, 71px, 0);
    opacity: 1; }
  50.9% {
    clip: rect(44px, 64px, 71px, 0);
    opacity: 0; }
  74.25% {
    clip: rect(13px, 64px, 67px, 0);
    opacity: 0; }
  75% {
    clip: rect(13px, 64px, 67px, 0);
    opacity: 1; }
  75.675% {
    clip: rect(52px, 64px, 63px, 0);
    opacity: 1; }
  76.35% {
    clip: rect(52px, 64px, 63px, 0);
    opacity: 0; }
  99% {
    clip: rect(21px, 64px, 75px, 0);
    opacity: 0; }
  100% {
    clip: rect(21px, 64px, 75px, 0);
    opacity: 1; }
  100.9% {
    clip: rect(28px, 64px, 41px, 0);
    opacity: 1; }
  101.8% {
    clip: rect(28px, 64px, 41px, 0);
    opacity: 0; } }
  .logo svg {
    position: absolute;
    top: 0px;
    left: 0px;
    fill: #fff;
    background: transparent;
    width: 64px;
    height: 80px; }
  .logo svg:nth-child(1) {
    position: relative;
    z-index: 1; }
  .logo svg:nth-child(2),
  .logo svg:nth-child(3),
  .logo svg:nth-child(4) {
    clip: rect(0, 0, 0, 0); }
  .logo svg:nth-child(2) {
    fill: rgba(255, 71, 140, 0.95);
    left: -3px;
    animation: logo-glitch-anim-1-long 9s linear infinite alternate-reverse; }
  .logo svg:nth-child(3) {
    fill: #ffff46;
    left: 2px;
    animation: logo-glitch-anim-2-long 9.5s linear infinite alternate-reverse; }
  .logo svg:nth-child(4) {
    fill: rgba(84, 123, 235, 0.8);
    top: -2px;
    left: 1px;
    animation: logo-glitch-anim-3-long 9.25s linear infinite alternate-reverse; }
  .logo:hover svg:nth-child(2) {
    animation: logo-glitch-anim-1 2s linear infinite alternate-reverse; }
  .logo:hover svg:nth-child(3) {
    animation: logo-glitch-anim-2 2.5s linear infinite alternate-reverse; }
  .logo:hover svg:nth-child(4) {
    animation: logo-glitch-anim-3 2.25s linear infinite alternate-reverse; }
  .logo svg:nth-child(1) {
    fill: #fff;
    transition: fill 0.36s; }
  @media only screen and (max-width: 1370px) {
    .logo svg {
      width: 50px;
      height: 62.5px; } }

.layout {
  position: relative;
  background-color: #0e0e0e;
  color: #fff; }
  .layout__left, .layout__right {
    position: absolute;
    display: block;
    bottom: 0;
    z-index: 1000;
    width: 14vw;
    padding-bottom: 6vw; }
  .layout__left {
    left: 0; }
  .layout__right {
    right: 0; }
  .layout-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    will-change: transform;
    transform: translateZ(0); }
    @media only screen and (max-width: 1199px) {
      .layout-bottom {
        display: none; } }

.side-info {
  display: block;
  text-align: right; }
  .side-info-link {
    cursor: pointer;
    display: block;
    float: right;
    clear: right;
    margin-top: 24px;
    color: #fff;
    transition: color 0.36s; }
    .side-info-link:hover {
      color: #ff478c; }

.scroll-to-top-btn {
  cursor: pointer;
  display: block;
  text-align: left;
  color: #fff;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.48s; }
  .scroll-to-top-btn.visible {
    visibility: visible;
    opacity: 1; }
  .scroll-to-top-btn:hover .scroll-to-top-btn__line::after {
    transform: scaleY(1); }
  .scroll-to-top-btn__line {
    position: relative;
    margin-bottom: 36px;
    background-color: rgba(255, 255, 255, 0.17); }
    .scroll-to-top-btn__line, .scroll-to-top-btn__line::after {
      display: block;
      width: 1px;
      height: 210px;
      height: 19vh; }
    .scroll-to-top-btn__line::after {
      content: "";
      position: absolute;
      background-color: #fff;
      transform: scaleY(0);
      transform-origin: 100% 100%;
      transition: background-color 0.48s linear, transform 0.48s cubic-bezier(0.2, 0.5, 0.3, 1); }

.primary-checkbox-btn {
  position: relative;
  z-index: 1;
  cursor: pointer;
  background-color: #0e0e0e; }
  .primary-checkbox-btn__input:checked ~ .primary-checkbox-btn__label,
  .primary-checkbox-btn:hover .primary-checkbox-btn__label {
    color: #111; }
    .primary-checkbox-btn__input:checked ~ .primary-checkbox-btn__label::before,
    .primary-checkbox-btn:hover .primary-checkbox-btn__label::before {
      opacity: 1;
      transform: scale(1); }
  .primary-checkbox-btn__label {
    cursor: pointer;
    padding: 1rem 1.5rem;
    font-size: 13px; }
    @media only screen and (min-width: 576px) {
      .primary-checkbox-btn__label {
        padding: 1rem 2.5rem; } }

.portfolio-card {
  opacity: 1;
  transform: translateY(0);
  transform-origin: 50% 0;
  transition: transform 0.64s 0.12s, opacity 0.64s 0.12s; }
  @media only screen and (max-width: 575px) {
    .portfolio-card {
      margin-top: 3rem !important; } }
  .portfolio-card--hidden {
    opacity: 0;
    transform: translateY(100px); }
  .portfolio-card__image-container {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
    color: #fff; }
    .portfolio-card__image-container:hover::before {
      opacity: 1; }
    .portfolio-card__image-container:hover::after {
      transform: scale(1);
      opacity: 1; }
    .portfolio-card__image-container::before {
      content: "";
      z-index: 1;
      display: block;
      opacity: 0;
      transition: opacity 0.64s ease; }
    .portfolio-card__image-container::after {
      content: "Подробнее";
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 0;
      right: 0;
      width: 100%;
      height: 1rem;
      margin-top: -0.5rem;
      text-align: center;
      transform: scale(1.25);
      opacity: 0;
      text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.24);
      transition: transform 0.36s cubic-bezier(0.2, 0.5, 0.3, 1), opacity 0.36s cubic-bezier(0.2, 0.5, 0.3, 1); }
    .portfolio-card__image-container--1::before {
      background: linear-gradient(-45deg, rgba(255, 71, 140, 0.25), #ff478c); }
    .portfolio-card__image-container--2::before {
      background: linear-gradient(-45deg, rgba(25, 104, 224, 0.3), #1968e0); }
    .portfolio-card__image-container--3::before {
      background: linear-gradient(-45deg, rgba(86, 25, 224, 0.3), #5619e0); }
    .portfolio-card__image-container--4::before {
      background: linear-gradient(-45deg, rgba(8, 191, 159, 0.3), #08bf9f); }
    .portfolio-card__image-container--5::before {
      background: linear-gradient(-45deg, rgba(234, 80, 80, 0.3), #ea5050); }
    .portfolio-card__image-container img {
      display: block;
      width: 100%;
      height: auto; }
  .portfolio-card__title {
    font-family: "PT Sans", sans-serif;
    font-size: 1rem;
    font-weight: normal;
    margin: 1.25rem 0; }
  .portfolio-card__categories {
    margin: 1rem 0; }
  .portfolio-card__category {
    cursor: pointer;
    position: relative;
    border: 1px solid #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 1rem 1.5rem;
    color: #fff;
    opacity: 0.75;
    transition: opacity 0.36s ease; }
    .portfolio-card__category:hover {
      opacity: 1; }
  .portfolio-card__description {
    margin-top: 1.25rem;
    font-family: "Roboto", sans-serif;
    font-size: 0.75rem;
    color: #8c8c8c; }
    .portfolio-card__description ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      .portfolio-card__description ul li {
        position: relative;
        padding: 0 0 0 22px;
        margin: 12px 0; }
        .portfolio-card__description ul li::before {
          content: "–";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 22px;
          height: 100%; }

.contact-form {
  position: relative;
  z-index: 1;
  color: #0e0e0e; }
  .contact-form--visible .contact-form__line {
    transform: scaleY(1);
    transition-property: transform;
    transition-duration: 0.48s; }
  .contact-form__lines {
    display: block;
    background-color: #0e0e0e;
    padding-bottom: 13px; }
  .contact-form__line {
    display: block;
    width: 100%;
    background-color: #fff;
    transform: scaleY(0);
    transition: none; }
    .contact-form__line:nth-child(1) {
      height: 13px;
      transition-delay: 0.48s; }
    .contact-form__line:nth-child(2) {
      height: 37px;
      margin-top: 44px;
      transition-delay: 0.72s; }
    .contact-form__line:nth-child(3) {
      height: 37px;
      margin-top: 50px;
      transition-delay: 0.96s; }
    .contact-form__line:nth-child(4) {
      height: 5px;
      margin-top: 54px;
      transition-delay: 1.2s; }
  .contact-form__content {
    position: relative;
    z-index: 1;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff; }
  .contact-form__wrapper {
    padding-top: 6vw;
    padding-bottom: 6vw; }
  .contact-form__v-lines {
    z-index: -1; }
  .contact-form__v-line {
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #f6f6f6; }
    .contact-form__v-line:nth-child(1) {
      left: 0%; }
    .contact-form__v-line:nth-child(2) {
      left: 33.33333%; }
    .contact-form__v-line:nth-child(3) {
      left: 66.66667%; }
    .contact-form__v-line:nth-child(4) {
      left: 100%; }
    @media only screen and (max-width: 991px) {
      .contact-form__v-line {
        display: none; } }
  .contact-form__title {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.6em;
    font-size: 6vh;
    max-width: 750px;
    letter-spacing: 0.14em; }
  .contact-form__description {
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 300;
    line-height: 2;
    color: #3a3a3a;
    padding-right: 10px; }
  .contact-form__container {
    position: relative;
    border-left: 1px solid #f6f6f6; }
  .contact-form__col {
    padding-top: 16px;
    padding-bottom: 16px; }
    @media only screen and (max-width: 991px) {
      .contact-form__col:last-child {
        padding-top: 24px;
        padding-bottom: 48px; } }
  .contact-form-contacts-group:not(:first-child) {
    margin-top: 36px;
    margin-top: 5vh; }
  .contact-form-contacts-group__title {
    margin-bottom: 24px; }
  .contact-form__phone, .contact-form__email {
    padding: 12px 0;
    display: block;
    color: #0e0e0e;
    transition: color 0.36s ease; }
    .contact-form__phone:hover, .contact-form__email:hover {
      color: #ff478c; }
  .contact-form__phone {
    font-family: "Gilroy", sans-serif;
    font-weight: bold;
    font-size: 1.25rem;
    letter-spacing: 0.05em; }
  .contact-form__email {
    text-transform: lowercase;
    letter-spacing: 0.2em; }
  .contact-form__icon-text .icon-text__text {
    text-transform: none;
    letter-spacing: 0.2em; }
  .contact-form__fields-container {
    margin-top: 36px;
    margin-top: 5vh; }

.textfield {
  position: relative;
  display: block; }
  .textfield--v-offset {
    margin-top: 0.5rem; }
  .textfield::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background-color: #ff478c;
    transform: scaleX(0);
    transform-origin: 50% 50%;
    transition: transform .32s ease; }
  .textfield--focused::after {
    transform: scaleX(1); }
  .textfield--black .textfield__input {
    border-bottom: 1px solid #0e0e0e; }
    .textfield--black .textfield__input:focus {
      border-color: #ff478c !important; }
  .textfield--black .textfield__label {
    color: #0e0e0e; }
  .textfield--fullwidth {
    width: 100%;
    max-width: 100%; }
  .textfield:not(.textfield--multiline) .textfield__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .textfield:not(.textfield--multiline).textfield--floated .textfield__label {
    transform: scale(0.75);
    top: 0; }
  .textfield--multiline.textfield--floated .textfield__label {
    opacity: 0; }
  .textfield--focused .textfield__label {
    color: #ff478c !important; }
  .textfield--floated .textfield__label {
    color: #3a3a3a;
    z-index: 2; }
  .textfield__label {
    display: block;
    position: absolute;
    z-index: 0;
    width: 100%;
    top: 24px;
    left: 0;
    font-size: 1rem;
    transform-origin: 0;
    transition: all 0.24s ease;
    pointer-events: none; }
  .textfield__input {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 24px 0 0.5rem 0;
    background: none;
    outline: none;
    border: none;
    font-size: 1rem;
    transition: border-color 0.24s ease; }

.footer {
  position: relative;
  z-index: 1;
  background-color: #0e0e0e;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }
  .footer__wrapper {
    max-width: 700px;
    text-align: center;
    margin: 0 auto; }

.primary-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.75); }
  .primary-modal--medium .primary-modal__box {
    max-width: 720px; }
  .primary-modal__box {
    display: block;
    position: relative !important;
    z-index: 2;
    padding: 2.5rem;
    background-color: #1e1e1e; }

.page-home {
  position: relative; }
  .page-home__scene {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    perspective: 1000px; }
  .page-home__wrapper {
    position: relative !important;
    z-index: 1;
    max-width: 830px;
    padding-top: 80px; }
    @media only screen and (max-width: 1440px) {
      .page-home__wrapper {
        max-width: 700px; } }
    @media only screen and (max-width: 1366px) {
      .page-home__wrapper {
        max-width: 670px; } }
  .page-home__title-col {
    flex: 1; }
  .page-home__title {
    position: relative;
    z-index: 1;
    font-weight: 500;
    font-family: "Gilroy", sans-serif;
    margin: 0;
    color: #fff; }
    @media only screen and (max-width: 575px) {
      .page-home__title {
        font-size: 2.25rem;
        font-size: 11vw; } }
  .page-home__description {
    line-height: 1.5;
    letter-spacing: 0.2em;
    margin-top: 0;
    margin-bottom: 0.25rem; }
    @media only screen and (max-width: 575px) {
      .page-home__description {
        margin-top: 0.75rem; } }
  .page-home-david {
    position: absolute;
    z-index: 0;
    top: unset !important;
    left: 0 !important;
    bottom: -1.65%;
    width: 23.2291667%;
    height: 100%;
    opacity: 0;
    transform: translateX(-80%);
    transition: opacity 1s ease, transform 2.5s cubic-bezier(0.11, 0.815, 0.11, 0.99); }
    @media only screen and (min-width: 1200px) {
      .page-home-david {
        left: 6% !important; } }
    .page-home-david.active {
      opacity: 1;
      transform: translateX(0); }
    .page-home-david__back-gradient, .page-home-david__back-image, .page-home-david__front {
      position: absolute !important;
      top: unset !important;
      right: unset !important;
      left: 0 !important;
      bottom: 0 !important;
      width: 100%; }
    .page-home-david__back-gradient {
      z-index: 0; }
    .page-home-david__back-image {
      z-index: 1;
      opacity: .25; }
    .page-home-david__front {
      z-index: 2;
      left: 2% !important; }
  .page-home-lighting {
    position: absolute !important;
    z-index: 2;
    top: unset !important;
    left: 11% !important;
    width: 47.6041667%;
    bottom: -1.65%;
    height: auto; }
    @media only screen and (min-width: 1200px) {
      .page-home-lighting {
        left: 17% !important; } }
    .page-home-lighting__image {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      transform: translateX(-10%) scaleX(0.5);
      transform-origin: 0 0;
      opacity: 0;
      transition: opacity 1s 0.75s ease, transform 2.5s 0.75s cubic-bezier(0.11, 0.815, 0.11, 0.99); }
      .page-home-lighting__image.active {
        opacity: 1;
        transform: translateX(0) scaleX(1);
        animation: opacity-pulse 5s linear 3.25s infinite alternate-reverse; }

.page-home-mosaic {
  display: block;
  position: absolute !important;
  top: 50% !important;
  left: unset !important;
  right: 20% !important;
  margin-top: -7.5rem;
  z-index: 0;
  display: block;
  width: 20rem;
  height: 15rem;
  opacity: 0;
  transition: opacity 5s ease .64s; }
  .page-home-mosaic.active {
    opacity: .12; }
  .page-home-mosaic__cube {
    width: 5rem;
    height: 5rem;
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.323), 5rem 0rem 0 0 rgba(255, 255, 255, 0.75), 10rem 0rem 0 0 rgba(255, 255, 255, 0.105), 15rem 0rem 0 0 rgba(255, 255, 255, 0.71), 0rem 5rem 0 0 rgba(255, 255, 255, 0.843), 5rem 5rem 0 0 rgba(255, 255, 255, 0.623), 10rem 5rem 0 0 rgba(255, 255, 255, 0.713), 15rem 5rem 0 0 rgba(255, 255, 255, 0.341), 0rem 10rem 0 0 rgba(255, 255, 255, 0.172), 5rem 10rem 0 0 rgba(255, 255, 255, 0.12), 10rem 10rem 0 0 rgba(255, 255, 255, 0.439), 15rem 10rem 0 0 rgba(255, 255, 255, 0.044);
    animation: mosaic_345rem12 14s linear infinite alternate;
    width: 5rem;
    height: 5rem; }

@keyframes mosaic_345rem12 {
  8.33333% {
    background: rgba(255, 255, 255, 0.109);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.129), 5rem 0rem 0 0 rgba(255, 255, 255, 0.168), 10rem 0rem 0 0 rgba(255, 255, 255, 0.621), 15rem 0rem 0 0 rgba(255, 255, 255, 0.806), 0rem 5rem 0 0 rgba(255, 255, 255, 0.648), 5rem 5rem 0 0 rgba(255, 255, 255, 0.314), 10rem 5rem 0 0 rgba(255, 255, 255, 0.906), 15rem 5rem 0 0 rgba(255, 255, 255, 0.372), 0rem 10rem 0 0 rgba(255, 255, 255, 0.91), 5rem 10rem 0 0 rgba(255, 255, 255, 0.065), 10rem 10rem 0 0 rgba(255, 255, 255, 0.486), 15rem 10rem 0 0 rgba(255, 255, 255, 0.546); }
  16.66667% {
    background: rgba(255, 255, 255, 0.923);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.594), 5rem 0rem 0 0 rgba(255, 255, 255, 0.063), 10rem 0rem 0 0 rgba(255, 255, 255, 0.55), 15rem 0rem 0 0 rgba(255, 255, 255, 0.598), 0rem 5rem 0 0 rgba(255, 255, 255, 0.542), 5rem 5rem 0 0 rgba(255, 255, 255, 0.036), 10rem 5rem 0 0 rgba(255, 255, 255, 0.236), 15rem 5rem 0 0 rgba(255, 255, 255, 0.375), 0rem 10rem 0 0 rgba(255, 255, 255, 0.11), 5rem 10rem 0 0 rgba(255, 255, 255, 0.554), 10rem 10rem 0 0 rgba(255, 255, 255, 0.475), 15rem 10rem 0 0 rgba(255, 255, 255, 0.099); }
  25% {
    background: rgba(255, 255, 255, 0.048);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.89), 5rem 0rem 0 0 rgba(255, 255, 255, 0.734), 10rem 0rem 0 0 rgba(255, 255, 255, 0.004), 15rem 0rem 0 0 rgba(255, 255, 255, 0.741), 0rem 5rem 0 0 rgba(255, 255, 255, 0.305), 5rem 5rem 0 0 rgba(255, 255, 255, 0.588), 10rem 5rem 0 0 rgba(255, 255, 255, 0.075), 15rem 5rem 0 0 rgba(255, 255, 255, 0.121), 0rem 10rem 0 0 rgba(255, 255, 255, 0.02), 5rem 10rem 0 0 rgba(255, 255, 255, 0.645), 10rem 10rem 0 0 rgba(255, 255, 255, 0.669), 15rem 10rem 0 0 rgba(255, 255, 255, 0.556); }
  33.33333% {
    background: rgba(255, 255, 255, 0.125);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.631), 5rem 0rem 0 0 rgba(255, 255, 255, 0.792), 10rem 0rem 0 0 rgba(255, 255, 255, 0.076), 15rem 0rem 0 0 rgba(255, 255, 255, 0.201), 0rem 5rem 0 0 rgba(255, 255, 255, 0.926), 5rem 5rem 0 0 rgba(255, 255, 255, 0.757), 10rem 5rem 0 0 rgba(255, 255, 255, 0.832), 15rem 5rem 0 0 rgba(255, 255, 255, 0.204), 0rem 10rem 0 0 rgba(255, 255, 255, 0.049), 5rem 10rem 0 0 rgba(255, 255, 255, 0.255), 10rem 10rem 0 0 rgba(255, 255, 255, 0.802), 15rem 10rem 0 0 rgba(255, 255, 255, 0.337); }
  41.66667% {
    background: rgba(255, 255, 255, 0.972);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.007), 5rem 0rem 0 0 rgba(255, 255, 255, 0.719), 10rem 0rem 0 0 rgba(255, 255, 255, 0.674), 15rem 0rem 0 0 rgba(255, 255, 255, 0.514), 0rem 5rem 0 0 rgba(255, 255, 255, 0.39), 5rem 5rem 0 0 rgba(255, 255, 255, 0.988), 10rem 5rem 0 0 rgba(255, 255, 255, 0.141), 15rem 5rem 0 0 rgba(255, 255, 255, 0.063), 0rem 10rem 0 0 rgba(255, 255, 255, 0.569), 5rem 10rem 0 0 rgba(255, 255, 255, 0.801), 10rem 10rem 0 0 rgba(255, 255, 255, 0.407), 15rem 10rem 0 0 rgba(255, 255, 255, 0.817); }
  50% {
    background: rgba(255, 255, 255, 0.704);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.464), 5rem 0rem 0 0 rgba(255, 255, 255, 0.859), 10rem 0rem 0 0 rgba(255, 255, 255, 0.059), 15rem 0rem 0 0 rgba(255, 255, 255, 0.53), 0rem 5rem 0 0 rgba(255, 255, 255, 0.666), 5rem 5rem 0 0 rgba(255, 255, 255, 0.744), 10rem 5rem 0 0 rgba(255, 255, 255, 0.067), 15rem 5rem 0 0 rgba(255, 255, 255, 0.336), 0rem 10rem 0 0 rgba(255, 255, 255, 0.697), 5rem 10rem 0 0 rgba(255, 255, 255, 0.485), 10rem 10rem 0 0 rgba(255, 255, 255, 0.754), 15rem 10rem 0 0 rgba(255, 255, 255, 0.993); }
  58.33333% {
    background: rgba(255, 255, 255, 0.205);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.734), 5rem 0rem 0 0 rgba(255, 255, 255, 0.962), 10rem 0rem 0 0 rgba(255, 255, 255, 0.034), 15rem 0rem 0 0 rgba(255, 255, 255, 0.818), 0rem 5rem 0 0 rgba(255, 255, 255, 0.5), 5rem 5rem 0 0 rgba(255, 255, 255, 0.956), 10rem 5rem 0 0 rgba(255, 255, 255, 0.965), 15rem 5rem 0 0 rgba(255, 255, 255, 0.983), 0rem 10rem 0 0 rgba(255, 255, 255, 0.477), 5rem 10rem 0 0 rgba(255, 255, 255, 0.328), 10rem 10rem 0 0 rgba(255, 255, 255, 0.018), 15rem 10rem 0 0 rgba(255, 255, 255, 0.762); }
  66.66667% {
    background: rgba(255, 255, 255, 0.161);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.664), 5rem 0rem 0 0 rgba(255, 255, 255, 0.65), 10rem 0rem 0 0 rgba(255, 255, 255, 0.062), 15rem 0rem 0 0 rgba(255, 255, 255, 0.1), 0rem 5rem 0 0 rgba(255, 255, 255, 0.264), 5rem 5rem 0 0 rgba(255, 255, 255, 0.621), 10rem 5rem 0 0 rgba(255, 255, 255, 0.877), 15rem 5rem 0 0 rgba(255, 255, 255, 0.056), 0rem 10rem 0 0 rgba(255, 255, 255, 0.885), 5rem 10rem 0 0 rgba(255, 255, 255, 0.248), 10rem 10rem 0 0 rgba(255, 255, 255, 0.218), 15rem 10rem 0 0 rgba(255, 255, 255, 0.653); }
  75% {
    background: rgba(255, 255, 255, 0.838);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.36), 5rem 0rem 0 0 rgba(255, 255, 255, 0.56), 10rem 0rem 0 0 rgba(255, 255, 255, 0.944), 15rem 0rem 0 0 rgba(255, 255, 255, 0.987), 0rem 5rem 0 0 rgba(255, 255, 255, 0.581), 5rem 5rem 0 0 rgba(255, 255, 255, 0.017), 10rem 5rem 0 0 rgba(255, 255, 255, 0.192), 15rem 5rem 0 0 rgba(255, 255, 255, 0.036), 0rem 10rem 0 0 rgba(255, 255, 255, 0.051), 5rem 10rem 0 0 rgba(255, 255, 255, 0.29), 10rem 10rem 0 0 rgba(255, 255, 255, 0.889), 15rem 10rem 0 0 rgba(255, 255, 255, 0.443); }
  83.33333% {
    background: rgba(255, 255, 255, 0.519);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.55), 5rem 0rem 0 0 rgba(255, 255, 255, 0.74), 10rem 0rem 0 0 rgba(255, 255, 255, 0.046), 15rem 0rem 0 0 rgba(255, 255, 255, 0.003), 0rem 5rem 0 0 rgba(255, 255, 255, 0.766), 5rem 5rem 0 0 rgba(255, 255, 255, 0.143), 10rem 5rem 0 0 rgba(255, 255, 255, 0.034), 15rem 5rem 0 0 rgba(255, 255, 255, 0.543), 0rem 10rem 0 0 rgba(255, 255, 255, 0.661), 5rem 10rem 0 0 rgba(255, 255, 255, 0.07), 10rem 10rem 0 0 rgba(255, 255, 255, 0.164), 15rem 10rem 0 0 rgba(255, 255, 255, 0.957); }
  91.66667% {
    background: rgba(255, 255, 255, 0.379);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.956), 5rem 0rem 0 0 rgba(255, 255, 255, 0.19), 10rem 0rem 0 0 rgba(255, 255, 255, 0.44), 15rem 0rem 0 0 rgba(255, 255, 255, 0.654), 0rem 5rem 0 0 rgba(255, 255, 255, 0.917), 5rem 5rem 0 0 rgba(255, 255, 255, 0.522), 10rem 5rem 0 0 rgba(255, 255, 255, 0.445), 15rem 5rem 0 0 rgba(255, 255, 255, 0.677), 0rem 10rem 0 0 rgba(255, 255, 255, 0.327), 5rem 10rem 0 0 rgba(255, 255, 255, 0.17), 10rem 10rem 0 0 rgba(255, 255, 255, 0.155), 15rem 10rem 0 0 rgba(255, 255, 255, 0.535); }
  100% {
    background: rgba(255, 255, 255, 0.776);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.171), 5rem 0rem 0 0 rgba(255, 255, 255, 0.045), 10rem 0rem 0 0 rgba(255, 255, 255, 0.01), 15rem 0rem 0 0 rgba(255, 255, 255, 0.467), 0rem 5rem 0 0 rgba(255, 255, 255, 0.519), 5rem 5rem 0 0 rgba(255, 255, 255, 0.608), 10rem 5rem 0 0 rgba(255, 255, 255, 0.491), 15rem 5rem 0 0 rgba(255, 255, 255, 0.929), 0rem 10rem 0 0 rgba(255, 255, 255, 0.598), 5rem 10rem 0 0 rgba(255, 255, 255, 0.363), 10rem 10rem 0 0 rgba(255, 255, 255, 0.425), 15rem 10rem 0 0 rgba(255, 255, 255, 0.597); } }
  .page-home-mosaic__mask {
    background: radial-gradient(rgba(0, 0, 0, 0), #0e0e0e); }

.portfolio-cards {
  position: relative;
  z-index: 0; }
  .portfolio-cards__filters {
    max-width: 700px;
    margin: 1rem -0.25rem 2rem -0.25rem; }
    @media only screen and (min-width: 992px) {
      .portfolio-cards__filters {
        margin: 1rem -1rem 7rem -1rem; } }
    .portfolio-cards__filters > .primary-checkbox-btn {
      margin: 0.25rem; }
      @media only screen and (min-width: 992px) {
        .portfolio-cards__filters > .primary-checkbox-btn {
          margin: 1rem; } }

.page-about {
  overflow-x: hidden; }

.about-cards-item__description, .about-start-text-col__description, .about-big-image-section__description, .about-projects-section__description, .about-immersion-section__description, .about-technologies-section__description, .about-not-afraid-section__description, .about-prices-section__description, .about-prices-section-price__description {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 13px;
  line-height: 2;
  color: #8c8c8c; }

.about-cards {
  position: relative;
  z-index: 1;
  margin-top: 100px;
  margin-bottom: 76px; }
  @media only screen and (min-width: 768px) {
    .about-cards {
      margin-top: 170px;
      margin-bottom: 100px; } }
  .about-cards.active .about-cards-item__title, .about-cards.active .about-cards-item__description, .about-cards.active .about-cards-item__bottom {
    transition: transform 0.36s ease, opacity 0.36s ease; }
  .about-cards.active .about-cards-overlay-image {
    transition: opacity 1s ease, transform 2.75s cubic-bezier(0.33, 0.51, 0.13, 1); }
  .about-cards.visible .about-cards-item__title, .about-cards.visible .about-cards-item__description, .about-cards.visible .about-cards-item__bottom {
    opacity: 1;
    transform: translateX(0); }
  .about-cards.visible .about-cards-overlay-image {
    opacity: 1;
    transform: translateY(0) scale(1); }
  .about-cards-overlay-image {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    width: 45%;
    height: auto;
    opacity: 0;
    transform: translateY(80px) scale(1.1);
    transition: none; }
    @media only screen and (max-width: 767px) {
      .about-cards-overlay-image {
        display: none; } }
    .about-cards-overlay-image__back {
      display: block;
      position: absolute;
      z-index: 0;
      bottom: 0;
      right: 0;
      width: 100.5%;
      height: auto; }
    .about-cards-overlay-image__front {
      display: block;
      position: relative;
      z-index: 1;
      width: 100%;
      height: auto; }
  .about-cards__back-image {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: -12%;
    right: -12%;
    width: 124%;
    transform: translateY(50%); }
  .about-cards__content {
    background-color: #1b1b1b; }
  .about-cards__wrapper {
    position: relative;
    z-index: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .about-cards__wrapper {
        width: 66.66667%; } }
  .about-cards-item {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 100%;
    overflow: hidden; }
    .about-cards-item:hover .about-cards-item__image {
      opacity: 1;
      transform: scale(1); }
    .about-cards-item:hover .about-cards-item__more-btn {
      opacity: 1; }
    .about-cards-item:nth-child(1) .about-cards-item__title {
      transition-delay: 1.2s !important; }
    .about-cards-item:nth-child(1) .about-cards-item__description {
      transition-delay: 1.44s !important; }
    .about-cards-item:nth-child(1) .about-cards-item__bottom {
      transition-delay: 1.68s !important; }
    .about-cards-item:nth-child(2) .about-cards-item__title {
      transition-delay: 1.64s !important; }
    .about-cards-item:nth-child(2) .about-cards-item__description {
      transition-delay: 1.88s !important; }
    .about-cards-item:nth-child(2) .about-cards-item__bottom {
      transition-delay: 2.12s !important; }
    .about-cards-item:nth-child(3) .about-cards-item__title {
      transition-delay: 2.08s !important; }
    .about-cards-item:nth-child(3) .about-cards-item__description {
      transition-delay: 2.32s !important; }
    .about-cards-item:nth-child(3) .about-cards-item__bottom {
      transition-delay: 2.56s !important; }
    .about-cards-item:nth-child(4) .about-cards-item__title {
      transition-delay: 2.52s !important; }
    .about-cards-item:nth-child(4) .about-cards-item__description {
      transition-delay: 2.76s !important; }
    .about-cards-item:nth-child(4) .about-cards-item__bottom {
      transition-delay: 3s !important; }
    @media only screen and (min-width: 768px) {
      .about-cards-item:nth-child(1) .about-cards-item__title {
        transition-delay: 1.2s; }
      .about-cards-item:nth-child(1) .about-cards-item__description {
        transition-delay: 1.44s; }
      .about-cards-item:nth-child(1) .about-cards-item__bottom {
        transition-delay: 1.68s; }
      .about-cards-item:nth-child(2) .about-cards-item__title {
        transition-delay: 1.64s; }
      .about-cards-item:nth-child(2) .about-cards-item__description {
        transition-delay: 1.88s; }
      .about-cards-item:nth-child(2) .about-cards-item__bottom {
        transition-delay: 2.12s; }
      .about-cards-item:nth-child(3) .about-cards-item__title {
        transition-delay: 2.08s; }
      .about-cards-item:nth-child(3) .about-cards-item__description {
        transition-delay: 2.32s; }
      .about-cards-item:nth-child(3) .about-cards-item__bottom {
        transition-delay: 2.56s; }
      .about-cards-item:nth-child(4) .about-cards-item__title {
        transition-delay: 2.52s; }
      .about-cards-item:nth-child(4) .about-cards-item__description {
        transition-delay: 2.76s; }
      .about-cards-item:nth-child(4) .about-cards-item__bottom {
        transition-delay: 3s; } }
    @media only screen and (min-width: 490px) {
      .about-cards-item {
        width: 50%;
        padding-top: 50%; } }
    .about-cards-item__image-container {
      overflow: hidden;
      transition: none; }
    .about-cards-item__image {
      width: 100%;
      z-index: 0;
      opacity: 0;
      transform: scale(1.4);
      transition: opacity 5s cubic-bezier(0.09, 0.54, 0, 1), transform 5s cubic-bezier(0.09, 0.54, 0, 1); }
    .about-cards-item__content {
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 12.8205128%; }
    .about-cards-item__title, .about-cards-item__description, .about-cards-item__bottom {
      opacity: 0;
      transform: translateX(-100px);
      transition: none; }
    .about-cards-item__title {
      font-family: "PT Sans", sans-serif;
      font-size: 1rem;
      font-weight: normal;
      letter-spacing: 0.2em;
      margin: 0; }
    .about-cards-item__description {
      margin-top: 1rem;
      margin-bottom: 1rem;
      max-height: 84px;
      overflow: hidden; }
      @media only screen and (min-width: 992px) {
        .about-cards-item__description {
          margin-top: 1.5rem; } }
    .about-cards-item__bottom {
      margin-top: auto;
      display: block; }
    .about-cards-item__more-btn {
      cursor: pointer;
      opacity: .1;
      color: #fff;
      transition: opacity 0.36s ease, color 0.36s ease; }
      .about-cards-item__more-btn:hover {
        color: #aaa; }

.about-start-text {
  margin-top: 72px;
  margin-bottom: 72px; }
  @media only screen and (min-width: 768px) {
    .about-start-text {
      margin-top: 100px;
      margin-bottom: 100px; } }
  .about-start-text.active .about-start-text-col__title::before {
    transition: transform 0.24s; }
  .about-start-text.active .about-start-text-col__title span {
    transition: transform 0.36s ease; }
  .about-start-text.active .about-start-text-col__description {
    transition: opacity 0.48s ease, transform 0.48s ease; }
  .about-start-text.visible .about-start-text-col__title::before {
    transform: scaleX(1); }
  .about-start-text.visible .about-start-text-col__title span {
    transform: translateX(0); }
  .about-start-text.visible .about-start-text-col__description {
    transform: translateX(0);
    opacity: 1; }
  @media only screen and (min-width: 1200px) {
    .about-start-text {
      padding-top: 225px;
      padding-bottom: 175px; } }
  .about-start-text__title {
    max-width: 390px;
    margin-left: -2px; }
  .about-start-text__description {
    margin: 3.5rem 0; }
    @media only screen and (min-width: 992px) {
      .about-start-text__description {
        width: 66.66667%; } }
  .about-start-text__cols {
    margin-top: 3.5rem; }
  .about-start-text-col {
    padding-bottom: 1.5rem;
    overflow: hidden; }
    .about-start-text-col:nth-child(1) .about-start-text-col__title::before {
      transition-delay: 0.48s; }
    .about-start-text-col:nth-child(1) .about-start-text-col__title span {
      transition-delay: 0.6s; }
    .about-start-text-col:nth-child(1) .about-start-text-col__description {
      transition-delay: 0.84s; }
    .about-start-text-col:nth-child(2) .about-start-text-col__title::before {
      transition-delay: 0.96s; }
    .about-start-text-col:nth-child(2) .about-start-text-col__title span {
      transition-delay: 1.08s; }
    .about-start-text-col:nth-child(2) .about-start-text-col__description {
      transition-delay: 1.32s; }
    .about-start-text-col:nth-child(3) .about-start-text-col__title::before {
      transition-delay: 1.44s; }
    .about-start-text-col:nth-child(3) .about-start-text-col__title span {
      transition-delay: 1.56s; }
    .about-start-text-col:nth-child(3) .about-start-text-col__description {
      transition-delay: 1.8s; }
    .about-start-text-col__title {
      position: relative;
      font-size: 1rem;
      font-family: "PT Sans", sans-serif;
      font-weight: normal;
      letter-spacing: 0.2em;
      margin: 0 0 1.5rem 0;
      padding-left: 25px;
      overflow: hidden; }
      .about-start-text-col__title span {
        display: inline-block;
        transform: translateY(100%);
        transition: none; }
      @media screen and (min-width: 1440px) {
        .about-start-text-col__title {
          padding-left: 37px; }
          .about-start-text-col__title::before {
            width: 22px; } }
      .about-start-text-col__title::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -1px;
        display: block;
        width: 12px;
        height: 2px;
        background-color: #fff;
        transform: scaleX(0);
        transform-origin: 0 0;
        transition: none; }
    .about-start-text-col__description {
      padding-right: 5px !important;
      opacity: 0;
      transform: translateX(-50px);
      transition: none; }

.about-big-image-section {
  margin-top: 72px;
  margin-bottom: 72px; }
  @media only screen and (min-width: 768px) {
    .about-big-image-section {
      margin-top: 100px;
      margin-bottom: 100px; } }
  .about-big-image-section__description,
  .about-big-image-section__title span, .about-big-image-section__image-container, .about-big-image-section__image {
    transition: none; }
  .about-big-image-section.active .about-big-image-section__title span {
    transition: transform 0.36s ease; }
  .about-big-image-section.active .about-big-image-section__description {
    transition: opacity 0.48s ease, transform 0.48s ease; }
  .about-big-image-section.active .about-big-image-section__image-container {
    will-change: transform;
    transition: transform 1.48s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .about-big-image-section.active .about-big-image-section__image {
    will-change: transform;
    transition: transform 1.48s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .about-big-image-section.visible .about-big-image-section__description {
    transform: translateX(0) translateZ(0);
    opacity: 1; }
  .about-big-image-section.visible .about-big-image-section__title span {
    transform: translateY(0) translateZ(0); }
    @media only screen and (min-width: 768px) {
      .about-big-image-section.visible .about-big-image-section__title span {
        transform: translateX(0) translateZ(0); } }
  .about-big-image-section.visible .about-big-image-section__image-container {
    transform: translateX(0) translateZ(0); }
  .about-big-image-section.visible .about-big-image-section__image {
    transform: translateX(0) scale(1) translateZ(0); }
  .about-big-image-section__title {
    margin-bottom: 2rem;
    overflow: hidden; }
    .about-big-image-section__title span {
      display: block;
      transform: translateY(100%);
      transition-delay: 0.12s !important; }
    @media only screen and (min-width: 768px) {
      .about-big-image-section__title {
        width: 83%;
        white-space: nowrap;
        writing-mode: vertical-lr;
        transform: rotate(-180deg);
        transform-origin: 50% 50%; }
        .about-big-image-section__title span {
          transform: translateX(-100%); } }
  .about-big-image-section__description {
    padding-right: 5px !important;
    opacity: 0;
    margin-top: auto;
    transform: translateX(-50px);
    transition-delay: 0.36s !important; }
  .about-big-image-section__image-col {
    position: relative; }
  .about-big-image-section__image-container, .about-big-image-section__image {
    transition-delay: 0.84s !important; }
  .about-big-image-section__image-container {
    display: block;
    width: calc(100% + 24px);
    max-width: 1154px;
    margin-top: 2rem;
    margin-left: 1px;
    overflow: hidden;
    transform-origin: 0 0;
    transform: translateX(-100%) translateZ(0); }
    @media only screen and (min-width: 768px) {
      .about-big-image-section__image-container {
        width: 170%;
        margin-top: 0; } }
  .about-big-image-section__image {
    display: block;
    width: 100%;
    transform: translateX(110%) scale(1.1) translateZ(0); }

.about-projects-section {
  margin-top: 72px;
  margin-bottom: 72px; }
  @media only screen and (min-width: 768px) {
    .about-projects-section {
      margin-top: 100px;
      margin-bottom: 100px; } }
  .about-projects-section__line, .about-projects-section__title, .about-projects-section__description {
    transition: none; }
  .about-projects-section__big-button-wrapper {
    overflow: hidden;
    transform-origin: 0 0;
    transform: translateX(-100%) translateZ(0); }
  .about-projects-section__big-button {
    transform: translateX(110%) translateZ(0); }
  .about-projects-section__line {
    transform: scaleX(0);
    transform-origin: 0 0;
    transition-delay: 0.64s !important; }
  .about-projects-section__title {
    opacity: 0;
    transform: translateX(-50px);
    transition-delay: 1s !important; }
  .about-projects-section__description {
    opacity: 0;
    transform: translateX(-50px);
    transition-delay: 1.24s !important; }
  .about-projects-section.active .about-projects-section__title, .about-projects-section.active .about-projects-section__description {
    transition: opacity 0.36s, transform 0.36s ease; }
  .about-projects-section.active .about-projects-section__line {
    transition: transform 0.48s cubic-bezier(0.2, 0.5, 0.3, 1); }
  .about-projects-section.active .about-projects-section__big-button-wrapper {
    transition: transform 0.64s cubic-bezier(0.2, 0.5, 0.3, 1); }
  .about-projects-section.active .about-projects-section__big-button {
    transition: color 0.36s ease, transform 0.64s cubic-bezier(0.2, 0.5, 0.3, 1); }
  .about-projects-section.visible .about-projects-section__title, .about-projects-section.visible .about-projects-section__description {
    opacity: 1;
    transform: translateX(0); }
  .about-projects-section.visible .about-projects-section__line {
    transform: scaleX(1); }
  .about-projects-section.visible .about-projects-section__big-button-wrapper, .about-projects-section.visible .about-projects-section__big-button {
    transform: translateX(0) translateZ(0); }
  .about-projects-section__big-button-wrapper {
    width: 100%;
    padding-top: 2px;
    padding-bottom: 2px; }
  .about-projects-section__big-button {
    cursor: pointer;
    position: relative;
    display: block;
    width: calc(100% - 4px);
    margin-left: 2px;
    text-align: center;
    font-weight: bold;
    padding: 90px 20px;
    background: #fff;
    outline: 2px solid #fff;
    border: 2px solid #000;
    color: #000;
    transition: color .24s ease; }
    .about-projects-section__big-button:hover {
      color: #fff; }
      .about-projects-section__big-button:hover::after {
        transform: scaleY(1); }
    .about-projects-section__big-button::after {
      content: "";
      display: block;
      z-index: -1;
      background: #000;
      transform: scaleY(0);
      transform-origin: 0 100%;
      transition: transform 0.64s cubic-bezier(0.2, 0.5, 0.3, 1); }
  .about-projects-section__line {
    position: absolute;
    display: block;
    width: 55%;
    top: 50%;
    left: 22.5%;
    height: 3px;
    margin-top: -1.5px;
    background: #fff; }
  @media only screen and (max-width: 575px) {
    .about-projects-section__content {
      padding: 2rem 0 0 0 !important; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .about-projects-section__content {
      padding: 0 0 0 1rem !important; } }
  .about-projects-section__title {
    margin: 0 0 2rem 0; }
  .about-projects-section__description {
    margin-top: 2rem; }

.about-video-section {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  z-index: 2; }
  .about-video-section__box, .about-video-section__wrapper {
    transition: none;
    transition-delay: 0.24s !important; }
  .about-video-section__box {
    overflow: hidden;
    transform-origin: 0 0;
    transform: translateX(-100%) translateZ(0); }
  .about-video-section__wrapper {
    transform: translateX(110%) translateZ(0); }
  .about-video-section__image {
    opacity: 0;
    transform: translateY(50px) scale(1.05);
    transition-delay: 1.24s !important; }
  .about-video-section__text {
    opacity: 0;
    transition-delay: 2s !important; }
  .about-video-section.active .about-video-section__box {
    will-change: transform; }
  .about-video-section.active .about-video-section__box,
  .about-video-section.active .about-video-section__wrapper {
    transition: transform 1.48s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .about-video-section.active .about-video-section__image {
    transition: transform 0.64s ease, opacity 0.64s ease; }
  .about-video-section.active .about-video-section__text {
    transition: opacity 0.64s ease; }
  .about-video-section.visible .about-video-section__box,
  .about-video-section.visible .about-video-section__wrapper {
    transform: translateX(0) translateZ(0); }
  .about-video-section.visible .about-video-section__image {
    opacity: 1;
    transform: translateY(0) scale(1); }
  .about-video-section.visible .about-video-section__text {
    opacity: 1; }
  .about-video-section__text {
    position: absolute;
    z-index: 1;
    top: 1rem;
    left: 1rem;
    right: unset;
    padding-bottom: 30px;
    transform-origin: 100% 0;
    transform: translateZ(0) translateX(-100%) translateY(0) rotate(-90deg); }
    @media only screen and (min-width: 992px) {
      .about-video-section__text {
        top: 45px;
        left: unset;
        right: 66.66667%;
        transform-origin: 100% 100%;
        transform: translateZ(0) translateX(0) translateY(-100%) rotate(-90deg); } }
  .about-video-section__image {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: -7.5%;
    width: 24.5%;
    height: 100%; }
  .about-video-section__wrapper {
    padding: 1rem 2rem 1rem 3rem;
    background-color: #0a0a0a; }
    @media only screen and (min-width: 992px) {
      .about-video-section__wrapper {
        padding: 45px 70px 45px 33.33333%; } }
  .about-video-section__video-container {
    display: block;
    min-height: 170px;
    padding-top: 62.5%;
    background-image: url("images/about/video-placeholder.png"); }

.about-immersion-section {
  position: relative;
  z-index: 1;
  margin-top: -120px;
  background-image: url("images/about/immersion--md.jpg");
  padding-top: 90px; }
  @media only screen and (min-width: 768px) {
    .about-immersion-section {
      background-image: url("images/about/immersion.jpg"); } }
  .about-immersion-section__image {
    opacity: 0;
    transform: translate3d(0, 100px, 0) scale(1.05); }
  .about-immersion-section__title {
    overflow: hidden; }
  .about-immersion-section__title-inner {
    transform: scale(0.9) translate3d(0, 100%, 0);
    transform-origin: 50% 100%; }
  .about-immersion-section__description {
    overflow: hidden; }
  .about-immersion-section__description-inner {
    opacity: 0; }
  .about-immersion-section.active .about-immersion-section__image {
    transition: transform 1.24s 0.36s cubic-bezier(0.2, 0.5, 0.3, 1), opacity 1.24s 0.36s cubic-bezier(0.2, 0.5, 0.3, 1); }
  .about-immersion-section.active .about-immersion-section__title-inner {
    transition: transform 0.72s 1.24s cubic-bezier(0.2, 0.5, 0.3, 1); }
  .about-immersion-section.active .about-immersion-section__description-inner {
    transition: opacity 0.72s 1.48s cubic-bezier(0.2, 0.5, 0.3, 1); }
  .about-immersion-section.visible .about-immersion-section__image {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
  .about-immersion-section.visible .about-immersion-section__title,
  .about-immersion-section.visible .about-immersion-section__title-inner {
    transform: translate3d(0, 0, 0) scale(1); }
  .about-immersion-section.visible .about-immersion-section__description-inner {
    opacity: 1; }
  .about-immersion-section__container {
    height: auto;
    padding-top: 72px;
    padding-bottom: 72px; }
    @media only screen and (min-width: 768px) {
      .about-immersion-section__container {
        height: 100vh;
        min-height: 700px;
        max-height: 1200px; } }
  .about-immersion-section__image {
    max-width: 100%;
    max-height: 560px; }
  .about-immersion-section__title {
    line-height: 1.65;
    margin-bottom: 2rem; }
  .about-immersion-section__description {
    margin-top: 2rem;
    color: #c6c6c6; }

.about-technologies-section {
  position: relative; }
  .about-technologies-section .about-technologies-section-card {
    opacity: 0; }
  .about-technologies-section .about-technologies-section__title,
  .about-technologies-section .about-technologies-section__description,
  .about-technologies-section .about-technologies-section__subtitle {
    overflow: hidden; }
  .about-technologies-section .about-technologies-section__title-inner {
    transform: scale(0.9) translate3d(0, 100%, 0); }
  .about-technologies-section .about-technologies-section__subtitle-inner {
    transform: scale(0.9) translate3d(0, -100%, 0); }
  .about-technologies-section .about-technologies-section__description-inner {
    transform: translate3d(-100%, 0, 0); }
  .about-technologies-section:not(.active).visible .about-technologies-section-card {
    transition-delay: 0s !important; }
  .about-technologies-section.active .about-technologies-section__title-inner {
    transition: transform 0.64s 0.48s ease; }
  .about-technologies-section.active .about-technologies-section__subtitle-inner {
    transition: transform 0.48s 0.72s ease; }
  .about-technologies-section.active .about-technologies-section__description-inner {
    transition: transform 0.64s 0.96s cubic-bezier(0.2, 0.5, 0.3, 1); }
  .about-technologies-section.active .about-technologies-section-card {
    transition: opacity 0.64s ease; }
  .about-technologies-section.visible .about-technologies-section__title-inner,
  .about-technologies-section.visible .about-technologies-section__description-inner,
  .about-technologies-section.visible .about-technologies-section__subtitle-inner {
    transform: scale(1) translate3d(0, 0, 0); }
  .about-technologies-section.visible .about-technologies-section-card {
    opacity: 1; }
  .about-technologies-section__title-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .about-technologies-section__title {
    margin-top: 0;
    margin-bottom: 1rem; }
  .about-technologies-section__subtitle {
    margin-top: auto;
    margin-bottom: 0; }
  .about-technologies-section__front-image {
    position: absolute;
    bottom: -15%;
    right: -30%;
    display: block;
    width: 50%;
    min-width: 300px;
    max-width: 640px; }
    @media only screen and (max-width: 991px) {
      .about-technologies-section__front-image {
        display: none; } }
  .about-technologies-section__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 3rem; }
    @media only screen and (min-width: 768px) {
      .about-technologies-section__cards {
        margin-top: 6rem; } }
  .about-technologies-section-card {
    display: block;
    width: 33.33333%; }
    @media only screen and (min-width: 420px) {
      .about-technologies-section-card {
        width: 25%; } }
    @media only screen and (min-width: 576px) {
      .about-technologies-section-card {
        width: 16.66667%; } }
    @media only screen and (min-width: 768px) {
      .about-technologies-section-card {
        width: 11.11111%; } }
    .about-technologies-section-card__inner {
      position: relative;
      display: block;
      width: 100%;
      padding-top: 100%; }
    .about-technologies-section-card:hover img {
      opacity: 1;
      filter: grayscale(0); }
    .about-technologies-section-card img {
      display: block;
      padding: 15%;
      opacity: 0.75;
      filter: grayscale(1);
      transition: opacity 0.48s ease, filter 0.48s ease; }

.about-not-afraid-section {
  margin-top: 72px; }
  @media only screen and (min-width: 768px) {
    .about-not-afraid-section {
      margin-top: 128px; } }
  .about-not-afraid-section__title {
    margin-top: 0; }
  .about-not-afraid-section__description {
    max-width: 100%;
    padding-right: 5px; }
    @media only screen and (min-width: 576px) {
      .about-not-afraid-section__description {
        max-width: 50%; } }
    @media only screen and (min-width: 768px) {
      .about-not-afraid-section__description {
        max-width: 33.33333%; } }
  .about-not-afraid-section__images {
    position: relative;
    height: 200px; }
    @media only screen and (min-width: 576px) {
      .about-not-afraid-section__images {
        height: 370px; } }
  .about-not-afraid-section__image-left {
    position: absolute;
    z-index: 2;
    top: 2rem;
    right: 0;
    width: 105%; }
    @media only screen and (min-width: 576px) {
      .about-not-afraid-section__image-left {
        width: 50%;
        right: 50%; } }
    @media only screen and (min-width: 768px) {
      .about-not-afraid-section__image-left {
        right: 66.66667%; } }
    @media only screen and (min-width: 1200px) {
      .about-not-afraid-section__image-left {
        width: 66%; } }
  .about-not-afraid-section__image-right {
    position: absolute;
    z-index: 1;
    top: -70%;
    right: -15%;
    width: 66%; }

.about-prices-section {
  margin-top: 1rem; }
  .about-prices-section__title {
    margin-top: 0; }
  .about-prices-section__description {
    margin-top: 2rem; }
  .about-prices-section__prices {
    margin-top: 2.75rem; }
  .about-prices-section-price {
    padding: 1.75rem 0;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 0.36s ease; }
    .about-prices-section-price:hover {
      background-color: rgba(255, 255, 255, 0.05); }
    .about-prices-section-price:hover .about-prices-section-price__line::after {
      transform: scaleX(1); }
    .about-prices-section-price:hover .about-prices-section-price__price {
      color: #ff478c; }
    .about-prices-section-price__text-col {
      padding-right: 5px !important; }
    .about-prices-section-price__title {
      margin: 0 0 1.5rem 0; }
    .about-prices-section-price__description {
      margin-top: 1.5rem; }
    .about-prices-section-price__line {
      width: 76%;
      height: 2px;
      background-color: #fff; }
      .about-prices-section-price__line::after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: #ff478c;
        transform: scaleX(0);
        transform-origin: 0 0;
        transition: transform 0.64s cubic-bezier(0.2, 0.5, 0.3, 1); }
    .about-prices-section-price__price {
      color: #fff;
      margin-bottom: 1.5rem;
      transition: color 0.36s ease; }

.page-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; }
  .page-not-found.active .page-not-found-mosaic {
    opacity: .1; }
  .page-not-found__container {
    padding-top: 50px;
    max-width: 1200px; }
  .page-not-found__title {
    position: relative !important;
    z-index: 1;
    color: #fff;
    letter-spacing: 1.5em;
    text-align: center;
    font-weight: 800;
    font-size: 3.5rem;
    font-size: 15vw;
    margin-right: -1.5em;
    margin-top: 0;
    margin-bottom: -.35em; }
    @media only screen and (min-width: 576px) {
      .page-not-found__title {
        font-size: 5rem;
        font-size: 12vw; } }
    @media only screen and (min-width: 992px) {
      .page-not-found__title {
        font-size: 8rem;
        font-size: 8vw;
        margin-bottom: -.45em; } }
    @media only screen and (min-width: 1920px) {
      .page-not-found__title {
        font-size: 150px; } }
  .page-not-found__content {
    display: block; }
    @media only screen and (min-width: 768px) {
      .page-not-found__content {
        margin: 0 4vw; } }
  .page-not-found-box {
    position: relative !important;
    z-index: 2;
    padding: 2.5rem;
    background-color: #1e1e1e; }
    .page-not-found-box__title {
      font-family: "PT Sans", sans-serif;
      font-size: 1rem;
      font-weight: normal;
      margin: 1em 0 1.2em 0; }
    .page-not-found-box__button-container {
      margin-top: 1.2rem;
      text-align: right; }
  .page-not-found__bottom-text {
    position: relative !important;
    z-index: 2;
    margin-top: 1rem; }
  .page-not-found__squid {
    display: block;
    position: absolute !important;
    z-index: 0;
    top: unset !important;
    left: unset !important;
    bottom: 0.5% !important;
    right: 0.5% !important;
    width: 35%;
    max-width: 820px;
    min-width: 200px;
    height: auto; }
  .page-not-found-mosaic {
    display: block;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    margin-left: -22.5rem;
    margin-top: -12.5rem;
    z-index: 0;
    display: block;
    width: 45rem;
    height: 25rem;
    opacity: 0;
    transition: opacity 5s ease .64s; }
    @media only screen and (max-width: 767px) {
      .page-not-found-mosaic {
        display: none !important; } }
    .page-not-found-mosaic__cube {
      width: 5rem;
      height: 5rem;
      box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.504), 5rem 0rem 0 0 rgba(255, 255, 255, 0.446), 10rem 0rem 0 0 rgba(255, 255, 255, 0.201), 15rem 0rem 0 0 rgba(255, 255, 255, 0.092), 20rem 0rem 0 0 rgba(255, 255, 255, 0.07), 25rem 0rem 0 0 rgba(255, 255, 255, 0.865), 30rem 0rem 0 0 rgba(255, 255, 255, 0.051), 35rem 0rem 0 0 rgba(255, 255, 255, 0.053), 40rem 0rem 0 0 rgba(255, 255, 255, 0.445), 0rem 5rem 0 0 rgba(255, 255, 255, 0.886), 5rem 5rem 0 0 rgba(255, 255, 255, 0.66), 10rem 5rem 0 0 rgba(255, 255, 255, 0.421), 15rem 5rem 0 0 rgba(255, 255, 255, 0.366), 20rem 5rem 0 0 rgba(255, 255, 255, 0.052), 25rem 5rem 0 0 rgba(255, 255, 255, 0.087), 30rem 5rem 0 0 rgba(255, 255, 255, 0.613), 35rem 5rem 0 0 rgba(255, 255, 255, 0.873), 40rem 5rem 0 0 rgba(255, 255, 255, 0.665), 0rem 10rem 0 0 rgba(255, 255, 255, 0.712), 5rem 10rem 0 0 rgba(255, 255, 255, 0.445), 10rem 10rem 0 0 rgba(255, 255, 255, 0.807), 15rem 10rem 0 0 rgba(255, 255, 255, 0.429), 20rem 10rem 0 0 rgba(255, 255, 255, 0.32), 25rem 10rem 0 0 rgba(255, 255, 255, 0.903), 30rem 10rem 0 0 rgba(255, 255, 255, 0.899), 35rem 10rem 0 0 rgba(255, 255, 255, 0.276), 40rem 10rem 0 0 rgba(255, 255, 255, 0.911), 0rem 15rem 0 0 rgba(255, 255, 255, 0.742), 5rem 15rem 0 0 rgba(255, 255, 255, 0.425), 10rem 15rem 0 0 rgba(255, 255, 255, 0.414), 15rem 15rem 0 0 rgba(255, 255, 255, 0.81), 20rem 15rem 0 0 rgba(255, 255, 255, 0.317), 25rem 15rem 0 0 rgba(255, 255, 255, 0.856), 30rem 15rem 0 0 rgba(255, 255, 255, 0.78), 35rem 15rem 0 0 rgba(255, 255, 255, 0.865), 40rem 15rem 0 0 rgba(255, 255, 255, 0.894), 0rem 20rem 0 0 rgba(255, 255, 255, 0.334), 5rem 20rem 0 0 rgba(255, 255, 255, 0.621), 10rem 20rem 0 0 rgba(255, 255, 255, 0.276), 15rem 20rem 0 0 rgba(255, 255, 255, 0.376), 20rem 20rem 0 0 rgba(255, 255, 255, 0.873), 25rem 20rem 0 0 rgba(255, 255, 255, 0.016), 30rem 20rem 0 0 rgba(255, 255, 255, 0.482), 35rem 20rem 0 0 rgba(255, 255, 255, 0.664), 40rem 20rem 0 0 rgba(255, 255, 255, 0.091);
      animation: mosaic_595rem12 14s linear infinite alternate; }

@keyframes mosaic_595rem12 {
  8.33333% {
    background: rgba(255, 255, 255, 0.669);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.463), 5rem 0rem 0 0 rgba(255, 255, 255, 0.562), 10rem 0rem 0 0 rgba(255, 255, 255, 0.912), 15rem 0rem 0 0 rgba(255, 255, 255, 0.502), 20rem 0rem 0 0 rgba(255, 255, 255, 0.073), 25rem 0rem 0 0 rgba(255, 255, 255, 0.752), 30rem 0rem 0 0 rgba(255, 255, 255, 0.431), 35rem 0rem 0 0 rgba(255, 255, 255, 0.41), 40rem 0rem 0 0 rgba(255, 255, 255, 0.039), 0rem 5rem 0 0 rgba(255, 255, 255, 0.904), 5rem 5rem 0 0 rgba(255, 255, 255, 0.189), 10rem 5rem 0 0 rgba(255, 255, 255, 0.1), 15rem 5rem 0 0 rgba(255, 255, 255, 0.727), 20rem 5rem 0 0 rgba(255, 255, 255, 0.004), 25rem 5rem 0 0 rgba(255, 255, 255, 0.544), 30rem 5rem 0 0 rgba(255, 255, 255, 0.567), 35rem 5rem 0 0 rgba(255, 255, 255, 0.635), 40rem 5rem 0 0 rgba(255, 255, 255, 0.095), 0rem 10rem 0 0 rgba(255, 255, 255, 0.854), 5rem 10rem 0 0 rgba(255, 255, 255, 0.603), 10rem 10rem 0 0 rgba(255, 255, 255, 0.388), 15rem 10rem 0 0 rgba(255, 255, 255, 0.474), 20rem 10rem 0 0 rgba(255, 255, 255, 0.058), 25rem 10rem 0 0 rgba(255, 255, 255, 0.031), 30rem 10rem 0 0 rgba(255, 255, 255, 0.633), 35rem 10rem 0 0 rgba(255, 255, 255, 0.44), 40rem 10rem 0 0 rgba(255, 255, 255, 0.915), 0rem 15rem 0 0 rgba(255, 255, 255, 0.983), 5rem 15rem 0 0 rgba(255, 255, 255, 0.501), 10rem 15rem 0 0 rgba(255, 255, 255, 0.191), 15rem 15rem 0 0 rgba(255, 255, 255, 0.567), 20rem 15rem 0 0 rgba(255, 255, 255, 0.234), 25rem 15rem 0 0 rgba(255, 255, 255, 0.412), 30rem 15rem 0 0 rgba(255, 255, 255, 0.226), 35rem 15rem 0 0 rgba(255, 255, 255, 0.805), 40rem 15rem 0 0 rgba(255, 255, 255, 0.325), 0rem 20rem 0 0 rgba(255, 255, 255, 0.944), 5rem 20rem 0 0 rgba(255, 255, 255, 0.073), 10rem 20rem 0 0 rgba(255, 255, 255, 0.701), 15rem 20rem 0 0 rgba(255, 255, 255, 0.006), 20rem 20rem 0 0 rgba(255, 255, 255, 0.772), 25rem 20rem 0 0 rgba(255, 255, 255, 0.807), 30rem 20rem 0 0 rgba(255, 255, 255, 0.257), 35rem 20rem 0 0 rgba(255, 255, 255, 0.661), 40rem 20rem 0 0 rgba(255, 255, 255, 0.945); }
  16.66667% {
    background: rgba(255, 255, 255, 0.753);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.56), 5rem 0rem 0 0 rgba(255, 255, 255, 0.226), 10rem 0rem 0 0 rgba(255, 255, 255, 0.436), 15rem 0rem 0 0 rgba(255, 255, 255, 0.996), 20rem 0rem 0 0 rgba(255, 255, 255, 0.232), 25rem 0rem 0 0 rgba(255, 255, 255, 0.889), 30rem 0rem 0 0 rgba(255, 255, 255, 0.62), 35rem 0rem 0 0 rgba(255, 255, 255, 0.888), 40rem 0rem 0 0 rgba(255, 255, 255, 0.042), 0rem 5rem 0 0 rgba(255, 255, 255, 0.013), 5rem 5rem 0 0 rgba(255, 255, 255, 0.838), 10rem 5rem 0 0 rgba(255, 255, 255, 0.783), 15rem 5rem 0 0 rgba(255, 255, 255, 0.416), 20rem 5rem 0 0 rgba(255, 255, 255, 0.947), 25rem 5rem 0 0 rgba(255, 255, 255, 0.243), 30rem 5rem 0 0 rgba(255, 255, 255, 0.989), 35rem 5rem 0 0 rgba(255, 255, 255, 0.808), 40rem 5rem 0 0 rgba(255, 255, 255, 0.822), 0rem 10rem 0 0 rgba(255, 255, 255, 0.402), 5rem 10rem 0 0 rgba(255, 255, 255, 0.416), 10rem 10rem 0 0 rgba(255, 255, 255, 0.181), 15rem 10rem 0 0 rgba(255, 255, 255, 0.789), 20rem 10rem 0 0 rgba(255, 255, 255, 0.104), 25rem 10rem 0 0 rgba(255, 255, 255, 0.817), 30rem 10rem 0 0 rgba(255, 255, 255, 0.743), 35rem 10rem 0 0 rgba(255, 255, 255, 0.485), 40rem 10rem 0 0 rgba(255, 255, 255, 0.034), 0rem 15rem 0 0 rgba(255, 255, 255, 0.567), 5rem 15rem 0 0 rgba(255, 255, 255, 0.982), 10rem 15rem 0 0 rgba(255, 255, 255, 0.819), 15rem 15rem 0 0 rgba(255, 255, 255, 0.811), 20rem 15rem 0 0 rgba(255, 255, 255, 0.171), 25rem 15rem 0 0 rgba(255, 255, 255, 0.897), 30rem 15rem 0 0 rgba(255, 255, 255, 0.268), 35rem 15rem 0 0 rgba(255, 255, 255, 0.243), 40rem 15rem 0 0 rgba(255, 255, 255, 0.369), 0rem 20rem 0 0 rgba(255, 255, 255, 0.528), 5rem 20rem 0 0 rgba(255, 255, 255, 0.847), 10rem 20rem 0 0 rgba(255, 255, 255, 0.116), 15rem 20rem 0 0 rgba(255, 255, 255, 0.496), 20rem 20rem 0 0 rgba(255, 255, 255, 0.989), 25rem 20rem 0 0 rgba(255, 255, 255, 0.874), 30rem 20rem 0 0 rgba(255, 255, 255, 0.996), 35rem 20rem 0 0 rgba(255, 255, 255, 0.176), 40rem 20rem 0 0 rgba(255, 255, 255, 0.74); }
  25% {
    background: rgba(255, 255, 255, 0.334);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.208), 5rem 0rem 0 0 rgba(255, 255, 255, 0.454), 10rem 0rem 0 0 rgba(255, 255, 255, 0.976), 15rem 0rem 0 0 rgba(255, 255, 255, 0.03), 20rem 0rem 0 0 rgba(255, 255, 255, 0.712), 25rem 0rem 0 0 rgba(255, 255, 255, 0.321), 30rem 0rem 0 0 rgba(255, 255, 255, 0.863), 35rem 0rem 0 0 rgba(255, 255, 255, 0.019), 40rem 0rem 0 0 rgba(255, 255, 255, 0.658), 0rem 5rem 0 0 rgba(255, 255, 255, 0.98), 5rem 5rem 0 0 rgba(255, 255, 255, 0.744), 10rem 5rem 0 0 rgba(255, 255, 255, 0.287), 15rem 5rem 0 0 rgba(255, 255, 255, 0.887), 20rem 5rem 0 0 rgba(255, 255, 255, 0.757), 25rem 5rem 0 0 rgba(255, 255, 255, 0.236), 30rem 5rem 0 0 rgba(255, 255, 255, 0.309), 35rem 5rem 0 0 rgba(255, 255, 255, 0.446), 40rem 5rem 0 0 rgba(255, 255, 255, 0.44), 0rem 10rem 0 0 rgba(255, 255, 255, 0.053), 5rem 10rem 0 0 rgba(255, 255, 255, 0.308), 10rem 10rem 0 0 rgba(255, 255, 255, 0.895), 15rem 10rem 0 0 rgba(255, 255, 255, 0.546), 20rem 10rem 0 0 rgba(255, 255, 255, 0.908), 25rem 10rem 0 0 rgba(255, 255, 255, 0.193), 30rem 10rem 0 0 rgba(255, 255, 255, 0.546), 35rem 10rem 0 0 rgba(255, 255, 255, 0.441), 40rem 10rem 0 0 rgba(255, 255, 255, 0.758), 0rem 15rem 0 0 rgba(255, 255, 255, 0.054), 5rem 15rem 0 0 rgba(255, 255, 255, 0.198), 10rem 15rem 0 0 rgba(255, 255, 255, 0.593), 15rem 15rem 0 0 rgba(255, 255, 255, 0.361), 20rem 15rem 0 0 rgba(255, 255, 255, 0.157), 25rem 15rem 0 0 rgba(255, 255, 255, 0.196), 30rem 15rem 0 0 rgba(255, 255, 255, 0.416), 35rem 15rem 0 0 rgba(255, 255, 255, 0.975), 40rem 15rem 0 0 rgba(255, 255, 255, 0.53), 0rem 20rem 0 0 rgba(255, 255, 255, 0.452), 5rem 20rem 0 0 rgba(255, 255, 255, 0.167), 10rem 20rem 0 0 rgba(255, 255, 255, 0.562), 15rem 20rem 0 0 rgba(255, 255, 255, 0.718), 20rem 20rem 0 0 rgba(255, 255, 255, 0.568), 25rem 20rem 0 0 rgba(255, 255, 255, 0.811), 30rem 20rem 0 0 rgba(255, 255, 255, 0.785), 35rem 20rem 0 0 rgba(255, 255, 255, 0.244), 40rem 20rem 0 0 rgba(255, 255, 255, 0.199); }
  33.33333% {
    background: rgba(255, 255, 255, 0.165);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.758), 5rem 0rem 0 0 rgba(255, 255, 255, 0.024), 10rem 0rem 0 0 rgba(255, 255, 255, 0.274), 15rem 0rem 0 0 rgba(255, 255, 255, 0.063), 20rem 0rem 0 0 rgba(255, 255, 255, 0.708), 25rem 0rem 0 0 rgba(255, 255, 255, 0.811), 30rem 0rem 0 0 rgba(255, 255, 255, 0.6), 35rem 0rem 0 0 rgba(255, 255, 255, 0.291), 40rem 0rem 0 0 rgba(255, 255, 255, 0.419), 0rem 5rem 0 0 rgba(255, 255, 255, 0.935), 5rem 5rem 0 0 rgba(255, 255, 255, 0.136), 10rem 5rem 0 0 rgba(255, 255, 255, 0.812), 15rem 5rem 0 0 rgba(255, 255, 255, 0.616), 20rem 5rem 0 0 rgba(255, 255, 255, 0.672), 25rem 5rem 0 0 rgba(255, 255, 255, 0.625), 30rem 5rem 0 0 rgba(255, 255, 255, 0.848), 35rem 5rem 0 0 rgba(255, 255, 255, 0.46), 40rem 5rem 0 0 rgba(255, 255, 255, 0.362), 0rem 10rem 0 0 rgba(255, 255, 255, 0.152), 5rem 10rem 0 0 rgba(255, 255, 255, 0.571), 10rem 10rem 0 0 rgba(255, 255, 255, 0.604), 15rem 10rem 0 0 rgba(255, 255, 255, 0.044), 20rem 10rem 0 0 rgba(255, 255, 255, 0.687), 25rem 10rem 0 0 rgba(255, 255, 255, 0.222), 30rem 10rem 0 0 rgba(255, 255, 255, 0.516), 35rem 10rem 0 0 rgba(255, 255, 255, 0.998), 40rem 10rem 0 0 rgba(255, 255, 255, 0.01), 0rem 15rem 0 0 rgba(255, 255, 255, 0.81), 5rem 15rem 0 0 rgba(255, 255, 255, 0.819), 10rem 15rem 0 0 rgba(255, 255, 255, 0.111), 15rem 15rem 0 0 rgba(255, 255, 255, 0.026), 20rem 15rem 0 0 rgba(255, 255, 255, 0.717), 25rem 15rem 0 0 rgba(255, 255, 255, 0.125), 30rem 15rem 0 0 rgba(255, 255, 255, 0.094), 35rem 15rem 0 0 rgba(255, 255, 255, 0.377), 40rem 15rem 0 0 rgba(255, 255, 255, 0.377), 0rem 20rem 0 0 rgba(255, 255, 255, 0.559), 5rem 20rem 0 0 rgba(255, 255, 255, 0.749), 10rem 20rem 0 0 rgba(255, 255, 255, 0.65), 15rem 20rem 0 0 rgba(255, 255, 255, 0.922), 20rem 20rem 0 0 rgba(255, 255, 255, 0.777), 25rem 20rem 0 0 rgba(255, 255, 255, 0.777), 30rem 20rem 0 0 rgba(255, 255, 255, 0.271), 35rem 20rem 0 0 rgba(255, 255, 255, 0.532), 40rem 20rem 0 0 rgba(255, 255, 255, 0.3); }
  41.66667% {
    background: rgba(255, 255, 255, 0.123);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.321), 5rem 0rem 0 0 rgba(255, 255, 255, 0.173), 10rem 0rem 0 0 rgba(255, 255, 255, 0.675), 15rem 0rem 0 0 rgba(255, 255, 255, 0.124), 20rem 0rem 0 0 rgba(255, 255, 255, 0.655), 25rem 0rem 0 0 rgba(255, 255, 255, 0.484), 30rem 0rem 0 0 rgba(255, 255, 255, 0.57), 35rem 0rem 0 0 rgba(255, 255, 255, 0.063), 40rem 0rem 0 0 rgba(255, 255, 255, 0.026), 0rem 5rem 0 0 rgba(255, 255, 255, 0.975), 5rem 5rem 0 0 rgba(255, 255, 255, 0.464), 10rem 5rem 0 0 rgba(255, 255, 255, 0.557), 15rem 5rem 0 0 rgba(255, 255, 255, 0.992), 20rem 5rem 0 0 rgba(255, 255, 255, 0.041), 25rem 5rem 0 0 rgba(255, 255, 255, 0.158), 30rem 5rem 0 0 rgba(255, 255, 255, 0.644), 35rem 5rem 0 0 rgba(255, 255, 255, 0.93), 40rem 5rem 0 0 rgba(255, 255, 255, 0.39), 0rem 10rem 0 0 rgba(255, 255, 255, 0.831), 5rem 10rem 0 0 rgba(255, 255, 255, 0.264), 10rem 10rem 0 0 rgba(255, 255, 255, 0.358), 15rem 10rem 0 0 rgba(255, 255, 255, 0.054), 20rem 10rem 0 0 rgba(255, 255, 255, 0.742), 25rem 10rem 0 0 rgba(255, 255, 255, 0.095), 30rem 10rem 0 0 rgba(255, 255, 255, 0.784), 35rem 10rem 0 0 rgba(255, 255, 255, 0.853), 40rem 10rem 0 0 rgba(255, 255, 255, 0.586), 0rem 15rem 0 0 rgba(255, 255, 255, 0.698), 5rem 15rem 0 0 rgba(255, 255, 255, 0.708), 10rem 15rem 0 0 rgba(255, 255, 255, 0.185), 15rem 15rem 0 0 rgba(255, 255, 255, 0.204), 20rem 15rem 0 0 rgba(255, 255, 255, 0.299), 25rem 15rem 0 0 rgba(255, 255, 255, 0.003), 30rem 15rem 0 0 rgba(255, 255, 255, 0.676), 35rem 15rem 0 0 rgba(255, 255, 255, 0.983), 40rem 15rem 0 0 rgba(255, 255, 255, 0.131), 0rem 20rem 0 0 rgba(255, 255, 255, 0.091), 5rem 20rem 0 0 rgba(255, 255, 255, 0.027), 10rem 20rem 0 0 rgba(255, 255, 255, 0.274), 15rem 20rem 0 0 rgba(255, 255, 255, 0.86), 20rem 20rem 0 0 rgba(255, 255, 255, 0.958), 25rem 20rem 0 0 rgba(255, 255, 255, 0.244), 30rem 20rem 0 0 rgba(255, 255, 255, 0.683), 35rem 20rem 0 0 rgba(255, 255, 255, 0.561), 40rem 20rem 0 0 rgba(255, 255, 255, 0.685); }
  50% {
    background: rgba(255, 255, 255, 0.934);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.081), 5rem 0rem 0 0 rgba(255, 255, 255, 0.24), 10rem 0rem 0 0 rgba(255, 255, 255, 0.194), 15rem 0rem 0 0 rgba(255, 255, 255, 0.55), 20rem 0rem 0 0 rgba(255, 255, 255, 0.707), 25rem 0rem 0 0 rgba(255, 255, 255, 0.899), 30rem 0rem 0 0 rgba(255, 255, 255, 0.929), 35rem 0rem 0 0 rgba(255, 255, 255, 0.029), 40rem 0rem 0 0 rgba(255, 255, 255, 0.206), 0rem 5rem 0 0 rgba(255, 255, 255, 0.465), 5rem 5rem 0 0 rgba(255, 255, 255, 0.594), 10rem 5rem 0 0 rgba(255, 255, 255, 0.677), 15rem 5rem 0 0 rgba(255, 255, 255, 0.538), 20rem 5rem 0 0 rgba(255, 255, 255, 0.329), 25rem 5rem 0 0 rgba(255, 255, 255, 0.334), 30rem 5rem 0 0 rgba(255, 255, 255, 0.388), 35rem 5rem 0 0 rgba(255, 255, 255, 0.554), 40rem 5rem 0 0 rgba(255, 255, 255, 0.216), 0rem 10rem 0 0 rgba(255, 255, 255, 0.416), 5rem 10rem 0 0 rgba(255, 255, 255, 0.425), 10rem 10rem 0 0 rgba(255, 255, 255, 0.527), 15rem 10rem 0 0 rgba(255, 255, 255, 0.402), 20rem 10rem 0 0 rgba(255, 255, 255, 0.114), 25rem 10rem 0 0 rgba(255, 255, 255, 0.272), 30rem 10rem 0 0 rgba(255, 255, 255, 0.784), 35rem 10rem 0 0 rgba(255, 255, 255, 0.252), 40rem 10rem 0 0 rgba(255, 255, 255, 0.793), 0rem 15rem 0 0 rgba(255, 255, 255, 0.104), 5rem 15rem 0 0 rgba(255, 255, 255, 0.443), 10rem 15rem 0 0 rgba(255, 255, 255, 0.384), 15rem 15rem 0 0 rgba(255, 255, 255, 0.366), 20rem 15rem 0 0 rgba(255, 255, 255, 0.764), 25rem 15rem 0 0 rgba(255, 255, 255, 0.715), 30rem 15rem 0 0 rgba(255, 255, 255, 0.713), 35rem 15rem 0 0 rgba(255, 255, 255, 0.55), 40rem 15rem 0 0 rgba(255, 255, 255, 0.804), 0rem 20rem 0 0 rgba(255, 255, 255, 0.906), 5rem 20rem 0 0 rgba(255, 255, 255, 0.629), 10rem 20rem 0 0 rgba(255, 255, 255, 0.569), 15rem 20rem 0 0 rgba(255, 255, 255, 0.017), 20rem 20rem 0 0 rgba(255, 255, 255, 0.205), 25rem 20rem 0 0 rgba(255, 255, 255, 0.879), 30rem 20rem 0 0 rgba(255, 255, 255, 0.807), 35rem 20rem 0 0 rgba(255, 255, 255, 0.478), 40rem 20rem 0 0 rgba(255, 255, 255, 0.534); }
  58.33333% {
    background: rgba(255, 255, 255, 0.968);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.124), 5rem 0rem 0 0 rgba(255, 255, 255, 0.911), 10rem 0rem 0 0 rgba(255, 255, 255, 0.263), 15rem 0rem 0 0 rgba(255, 255, 255, 0.993), 20rem 0rem 0 0 rgba(255, 255, 255, 0.031), 25rem 0rem 0 0 rgba(255, 255, 255, 0.417), 30rem 0rem 0 0 rgba(255, 255, 255, 0.218), 35rem 0rem 0 0 rgba(255, 255, 255, 0.491), 40rem 0rem 0 0 rgba(255, 255, 255, 0.164), 0rem 5rem 0 0 rgba(255, 255, 255, 0.65), 5rem 5rem 0 0 rgba(255, 255, 255, 0.985), 10rem 5rem 0 0 rgba(255, 255, 255, 0.528), 15rem 5rem 0 0 rgba(255, 255, 255, 0.516), 20rem 5rem 0 0 rgba(255, 255, 255, 0.129), 25rem 5rem 0 0 rgba(255, 255, 255, 0.343), 30rem 5rem 0 0 rgba(255, 255, 255, 0.346), 35rem 5rem 0 0 rgba(255, 255, 255, 0.971), 40rem 5rem 0 0 rgba(255, 255, 255, 0.681), 0rem 10rem 0 0 rgba(255, 255, 255, 0.456), 5rem 10rem 0 0 rgba(255, 255, 255, 0.604), 10rem 10rem 0 0 rgba(255, 255, 255, 0.906), 15rem 10rem 0 0 rgba(255, 255, 255, 0.125), 20rem 10rem 0 0 rgba(255, 255, 255, 0.338), 25rem 10rem 0 0 rgba(255, 255, 255, 0.304), 30rem 10rem 0 0 rgba(255, 255, 255, 0.094), 35rem 10rem 0 0 rgba(255, 255, 255, 0.009), 40rem 10rem 0 0 rgba(255, 255, 255, 0.11), 0rem 15rem 0 0 rgba(255, 255, 255, 0.935), 5rem 15rem 0 0 rgba(255, 255, 255, 0.148), 10rem 15rem 0 0 rgba(255, 255, 255, 0.932), 15rem 15rem 0 0 rgba(255, 255, 255, 0.963), 20rem 15rem 0 0 rgba(255, 255, 255, 0.596), 25rem 15rem 0 0 rgba(255, 255, 255, 0.748), 30rem 15rem 0 0 rgba(255, 255, 255, 0.332), 35rem 15rem 0 0 rgba(255, 255, 255, 0.496), 40rem 15rem 0 0 rgba(255, 255, 255, 0.65), 0rem 20rem 0 0 rgba(255, 255, 255, 0.201), 5rem 20rem 0 0 rgba(255, 255, 255, 0.042), 10rem 20rem 0 0 rgba(255, 255, 255, 0.854), 15rem 20rem 0 0 rgba(255, 255, 255, 0.501), 20rem 20rem 0 0 rgba(255, 255, 255, 0.508), 25rem 20rem 0 0 rgba(255, 255, 255, 0.55), 30rem 20rem 0 0 rgba(255, 255, 255, 0.542), 35rem 20rem 0 0 rgba(255, 255, 255, 0.305), 40rem 20rem 0 0 rgba(255, 255, 255, 0.665); }
  66.66667% {
    background: rgba(255, 255, 255, 0.761);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.001), 5rem 0rem 0 0 rgba(255, 255, 255, 0.539), 10rem 0rem 0 0 rgba(255, 255, 255, 0.835), 15rem 0rem 0 0 rgba(255, 255, 255, 0.637), 20rem 0rem 0 0 rgba(255, 255, 255, 0.668), 25rem 0rem 0 0 rgba(255, 255, 255, 0.224), 30rem 0rem 0 0 rgba(255, 255, 255, 0.056), 35rem 0rem 0 0 rgba(255, 255, 255, 0.871), 40rem 0rem 0 0 rgba(255, 255, 255, 0.89), 0rem 5rem 0 0 rgba(255, 255, 255, 0.47), 5rem 5rem 0 0 rgba(255, 255, 255, 0.04), 10rem 5rem 0 0 rgba(255, 255, 255, 0.043), 15rem 5rem 0 0 rgba(255, 255, 255, 0.394), 20rem 5rem 0 0 rgba(255, 255, 255, 0.498), 25rem 5rem 0 0 rgba(255, 255, 255, 0.733), 30rem 5rem 0 0 rgba(255, 255, 255, 0.894), 35rem 5rem 0 0 rgba(255, 255, 255, 0.114), 40rem 5rem 0 0 rgba(255, 255, 255, 0.024), 0rem 10rem 0 0 rgba(255, 255, 255, 0.466), 5rem 10rem 0 0 rgba(255, 255, 255, 0.425), 10rem 10rem 0 0 rgba(255, 255, 255, 0.235), 15rem 10rem 0 0 rgba(255, 255, 255, 0.123), 20rem 10rem 0 0 rgba(255, 255, 255, 0.853), 25rem 10rem 0 0 rgba(255, 255, 255, 0.803), 30rem 10rem 0 0 rgba(255, 255, 255, 0.802), 35rem 10rem 0 0 rgba(255, 255, 255, 0.744), 40rem 10rem 0 0 rgba(255, 255, 255, 0.394), 0rem 15rem 0 0 rgba(255, 255, 255, 0.318), 5rem 15rem 0 0 rgba(255, 255, 255, 0.286), 10rem 15rem 0 0 rgba(255, 255, 255, 0.42), 15rem 15rem 0 0 rgba(255, 255, 255, 0.73), 20rem 15rem 0 0 rgba(255, 255, 255, 0.121), 25rem 15rem 0 0 rgba(255, 255, 255, 0.354), 30rem 15rem 0 0 rgba(255, 255, 255, 0.811), 35rem 15rem 0 0 rgba(255, 255, 255, 0.236), 40rem 15rem 0 0 rgba(255, 255, 255, 0.605), 0rem 20rem 0 0 rgba(255, 255, 255, 0.29), 5rem 20rem 0 0 rgba(255, 255, 255, 0.062), 10rem 20rem 0 0 rgba(255, 255, 255, 0.653), 15rem 20rem 0 0 rgba(255, 255, 255, 0.024), 20rem 20rem 0 0 rgba(255, 255, 255, 0.422), 25rem 20rem 0 0 rgba(255, 255, 255, 0.72), 30rem 20rem 0 0 rgba(255, 255, 255, 0.964), 35rem 20rem 0 0 rgba(255, 255, 255, 0.02), 40rem 20rem 0 0 rgba(255, 255, 255, 0.184); }
  75% {
    background: rgba(255, 255, 255, 0.611);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.103), 5rem 0rem 0 0 rgba(255, 255, 255, 0.405), 10rem 0rem 0 0 rgba(255, 255, 255, 0.041), 15rem 0rem 0 0 rgba(255, 255, 255, 0.999), 20rem 0rem 0 0 rgba(255, 255, 255, 0.06), 25rem 0rem 0 0 rgba(255, 255, 255, 0.364), 30rem 0rem 0 0 rgba(255, 255, 255, 0.185), 35rem 0rem 0 0 rgba(255, 255, 255, 0.179), 40rem 0rem 0 0 rgba(255, 255, 255, 0.838), 0rem 5rem 0 0 rgba(255, 255, 255, 0.814), 5rem 5rem 0 0 rgba(255, 255, 255, 0.383), 10rem 5rem 0 0 rgba(255, 255, 255, 0.546), 15rem 5rem 0 0 rgba(255, 255, 255, 0.447), 20rem 5rem 0 0 rgba(255, 255, 255, 0.995), 25rem 5rem 0 0 rgba(255, 255, 255, 0.692), 30rem 5rem 0 0 rgba(255, 255, 255, 0.597), 35rem 5rem 0 0 rgba(255, 255, 255, 0.063), 40rem 5rem 0 0 rgba(255, 255, 255, 0.62), 0rem 10rem 0 0 rgba(255, 255, 255, 0.432), 5rem 10rem 0 0 rgba(255, 255, 255, 0.979), 10rem 10rem 0 0 rgba(255, 255, 255, 0.808), 15rem 10rem 0 0 rgba(255, 255, 255, 0.193), 20rem 10rem 0 0 rgba(255, 255, 255, 0.694), 25rem 10rem 0 0 rgba(255, 255, 255, 0.47), 30rem 10rem 0 0 rgba(255, 255, 255, 0.978), 35rem 10rem 0 0 rgba(255, 255, 255, 0.864), 40rem 10rem 0 0 rgba(255, 255, 255, 0.62), 0rem 15rem 0 0 rgba(255, 255, 255, 0.768), 5rem 15rem 0 0 rgba(255, 255, 255, 0.462), 10rem 15rem 0 0 rgba(255, 255, 255, 0.806), 15rem 15rem 0 0 rgba(255, 255, 255, 0.614), 20rem 15rem 0 0 rgba(255, 255, 255, 0.947), 25rem 15rem 0 0 rgba(255, 255, 255, 0.65), 30rem 15rem 0 0 rgba(255, 255, 255, 0.233), 35rem 15rem 0 0 rgba(255, 255, 255, 0.921), 40rem 15rem 0 0 rgba(255, 255, 255, 0.644), 0rem 20rem 0 0 rgba(255, 255, 255, 0.19), 5rem 20rem 0 0 rgba(255, 255, 255, 0.286), 10rem 20rem 0 0 rgba(255, 255, 255, 0.726), 15rem 20rem 0 0 rgba(255, 255, 255, 0.57), 20rem 20rem 0 0 rgba(255, 255, 255, 0.119), 25rem 20rem 0 0 rgba(255, 255, 255, 0.159), 30rem 20rem 0 0 rgba(255, 255, 255, 0.062), 35rem 20rem 0 0 rgba(255, 255, 255, 0.122), 40rem 20rem 0 0 rgba(255, 255, 255, 0.434); }
  83.33333% {
    background: rgba(255, 255, 255, 0.965);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.764), 5rem 0rem 0 0 rgba(255, 255, 255, 0.884), 10rem 0rem 0 0 rgba(255, 255, 255, 0.243), 15rem 0rem 0 0 rgba(255, 255, 255, 0.846), 20rem 0rem 0 0 rgba(255, 255, 255, 0.654), 25rem 0rem 0 0 rgba(255, 255, 255, 0.911), 30rem 0rem 0 0 rgba(255, 255, 255, 0.276), 35rem 0rem 0 0 rgba(255, 255, 255, 0.666), 40rem 0rem 0 0 rgba(255, 255, 255, 0.548), 0rem 5rem 0 0 rgba(255, 255, 255, 0.25), 5rem 5rem 0 0 rgba(255, 255, 255, 0.36), 10rem 5rem 0 0 rgba(255, 255, 255, 0.637), 15rem 5rem 0 0 rgba(255, 255, 255, 0.248), 20rem 5rem 0 0 rgba(255, 255, 255, 0.58), 25rem 5rem 0 0 rgba(255, 255, 255, 0.532), 30rem 5rem 0 0 rgba(255, 255, 255, 0.813), 35rem 5rem 0 0 rgba(255, 255, 255, 0.092), 40rem 5rem 0 0 rgba(255, 255, 255, 0.332), 0rem 10rem 0 0 rgba(255, 255, 255, 0.873), 5rem 10rem 0 0 rgba(255, 255, 255, 0.894), 10rem 10rem 0 0 rgba(255, 255, 255, 0.829), 15rem 10rem 0 0 rgba(255, 255, 255, 0.395), 20rem 10rem 0 0 rgba(255, 255, 255, 0.246), 25rem 10rem 0 0 rgba(255, 255, 255, 0.619), 30rem 10rem 0 0 rgba(255, 255, 255, 0.373), 35rem 10rem 0 0 rgba(255, 255, 255, 0.282), 40rem 10rem 0 0 rgba(255, 255, 255, 0.108), 0rem 15rem 0 0 rgba(255, 255, 255, 0.605), 5rem 15rem 0 0 rgba(255, 255, 255, 0.127), 10rem 15rem 0 0 rgba(255, 255, 255, 0.431), 15rem 15rem 0 0 rgba(255, 255, 255, 0.539), 20rem 15rem 0 0 rgba(255, 255, 255, 0.688), 25rem 15rem 0 0 rgba(255, 255, 255, 0.188), 30rem 15rem 0 0 rgba(255, 255, 255, 0.231), 35rem 15rem 0 0 rgba(255, 255, 255, 0.149), 40rem 15rem 0 0 rgba(255, 255, 255, 0.526), 0rem 20rem 0 0 rgba(255, 255, 255, 0.859), 5rem 20rem 0 0 rgba(255, 255, 255, 0.203), 10rem 20rem 0 0 rgba(255, 255, 255, 0.601), 15rem 20rem 0 0 rgba(255, 255, 255, 0.371), 20rem 20rem 0 0 rgba(255, 255, 255, 0.096), 25rem 20rem 0 0 rgba(255, 255, 255, 0.651), 30rem 20rem 0 0 rgba(255, 255, 255, 0.101), 35rem 20rem 0 0 rgba(255, 255, 255, 0.515), 40rem 20rem 0 0 rgba(255, 255, 255, 0.942); }
  91.66667% {
    background: rgba(255, 255, 255, 0.685);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.574), 5rem 0rem 0 0 rgba(255, 255, 255, 0.729), 10rem 0rem 0 0 rgba(255, 255, 255, 0.22), 15rem 0rem 0 0 rgba(255, 255, 255, 0.761), 20rem 0rem 0 0 rgba(255, 255, 255, 0.629), 25rem 0rem 0 0 rgba(255, 255, 255, 0.019), 30rem 0rem 0 0 rgba(255, 255, 255, 0.492), 35rem 0rem 0 0 rgba(255, 255, 255, 0.095), 40rem 0rem 0 0 rgba(255, 255, 255, 0.268), 0rem 5rem 0 0 rgba(255, 255, 255, 0.716), 5rem 5rem 0 0 rgba(255, 255, 255, 0.15), 10rem 5rem 0 0 rgba(255, 255, 255, 0.904), 15rem 5rem 0 0 rgba(255, 255, 255, 0.826), 20rem 5rem 0 0 rgba(255, 255, 255, 0.91), 25rem 5rem 0 0 rgba(255, 255, 255, 0.917), 30rem 5rem 0 0 rgba(255, 255, 255, 0.524), 35rem 5rem 0 0 rgba(255, 255, 255, 0.406), 40rem 5rem 0 0 rgba(255, 255, 255, 0.464), 0rem 10rem 0 0 rgba(255, 255, 255, 0.257), 5rem 10rem 0 0 rgba(255, 255, 255, 0.175), 10rem 10rem 0 0 rgba(255, 255, 255, 0.63), 15rem 10rem 0 0 rgba(255, 255, 255, 0.893), 20rem 10rem 0 0 rgba(255, 255, 255, 0.977), 25rem 10rem 0 0 rgba(255, 255, 255, 0.737), 30rem 10rem 0 0 rgba(255, 255, 255, 0.848), 35rem 10rem 0 0 rgba(255, 255, 255, 0.945), 40rem 10rem 0 0 rgba(255, 255, 255, 0.631), 0rem 15rem 0 0 rgba(255, 255, 255, 0.141), 5rem 15rem 0 0 rgba(255, 255, 255, 0.724), 10rem 15rem 0 0 rgba(255, 255, 255, 0.428), 15rem 15rem 0 0 rgba(255, 255, 255, 0.508), 20rem 15rem 0 0 rgba(255, 255, 255, 0.319), 25rem 15rem 0 0 rgba(255, 255, 255, 0.644), 30rem 15rem 0 0 rgba(255, 255, 255, 0.925), 35rem 15rem 0 0 rgba(255, 255, 255, 0.893), 40rem 15rem 0 0 rgba(255, 255, 255, 0.91), 0rem 20rem 0 0 rgba(255, 255, 255, 0.828), 5rem 20rem 0 0 rgba(255, 255, 255, 0.459), 10rem 20rem 0 0 rgba(255, 255, 255, 0.335), 15rem 20rem 0 0 rgba(255, 255, 255, 0.175), 20rem 20rem 0 0 rgba(255, 255, 255, 0.266), 25rem 20rem 0 0 rgba(255, 255, 255, 0.314), 30rem 20rem 0 0 rgba(255, 255, 255, 0.782), 35rem 20rem 0 0 rgba(255, 255, 255, 0.184), 40rem 20rem 0 0 rgba(255, 255, 255, 0.621); }
  100% {
    background: rgba(255, 255, 255, 0.548);
    box-shadow: 0rem 0rem 0 0 rgba(255, 255, 255, 0.072), 5rem 0rem 0 0 rgba(255, 255, 255, 0.172), 10rem 0rem 0 0 rgba(255, 255, 255, 0.108), 15rem 0rem 0 0 rgba(255, 255, 255, 0.218), 20rem 0rem 0 0 rgba(255, 255, 255, 0.187), 25rem 0rem 0 0 rgba(255, 255, 255, 0.731), 30rem 0rem 0 0 rgba(255, 255, 255, 0.652), 35rem 0rem 0 0 rgba(255, 255, 255, 0.512), 40rem 0rem 0 0 rgba(255, 255, 255, 0.203), 0rem 5rem 0 0 rgba(255, 255, 255, 0.411), 5rem 5rem 0 0 rgba(255, 255, 255, 0.352), 10rem 5rem 0 0 rgba(255, 255, 255, 0.549), 15rem 5rem 0 0 rgba(255, 255, 255, 0.884), 20rem 5rem 0 0 rgba(255, 255, 255, 0.258), 25rem 5rem 0 0 rgba(255, 255, 255, 0.901), 30rem 5rem 0 0 rgba(255, 255, 255, 0.733), 35rem 5rem 0 0 rgba(255, 255, 255, 0.263), 40rem 5rem 0 0 rgba(255, 255, 255, 0.243), 0rem 10rem 0 0 rgba(255, 255, 255, 0.918), 5rem 10rem 0 0 rgba(255, 255, 255, 0.656), 10rem 10rem 0 0 rgba(255, 255, 255, 0.08), 15rem 10rem 0 0 rgba(255, 255, 255, 0.215), 20rem 10rem 0 0 rgba(255, 255, 255, 0.776), 25rem 10rem 0 0 rgba(255, 255, 255, 0.624), 30rem 10rem 0 0 rgba(255, 255, 255, 0.625), 35rem 10rem 0 0 rgba(255, 255, 255, 0.892), 40rem 10rem 0 0 rgba(255, 255, 255, 0.095), 0rem 15rem 0 0 rgba(255, 255, 255, 0.658), 5rem 15rem 0 0 rgba(255, 255, 255, 0.364), 10rem 15rem 0 0 rgba(255, 255, 255, 0.22), 15rem 15rem 0 0 rgba(255, 255, 255, 0.409), 20rem 15rem 0 0 rgba(255, 255, 255, 0.858), 25rem 15rem 0 0 rgba(255, 255, 255, 0.86), 30rem 15rem 0 0 rgba(255, 255, 255, 0.136), 35rem 15rem 0 0 rgba(255, 255, 255, 0.155), 40rem 15rem 0 0 rgba(255, 255, 255, 0.63), 0rem 20rem 0 0 rgba(255, 255, 255, 0.563), 5rem 20rem 0 0 rgba(255, 255, 255, 0.904), 10rem 20rem 0 0 rgba(255, 255, 255, 0.452), 15rem 20rem 0 0 rgba(255, 255, 255, 0.875), 20rem 20rem 0 0 rgba(255, 255, 255, 0.731), 25rem 20rem 0 0 rgba(255, 255, 255, 0.084), 30rem 20rem 0 0 rgba(255, 255, 255, 0.46), 35rem 20rem 0 0 rgba(255, 255, 255, 0.45), 40rem 20rem 0 0 rgba(255, 255, 255, 0.885); } }
    .page-not-found-mosaic__mask {
      background: radial-gradient(rgba(0, 0, 0, 0), #0e0e0e); }

.page-contacts {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .page-contacts__wrapper {
    padding: 2rem 0; }
  .page-contacts__title {
    font-size: 1.75rem;
    font-size: 8vw;
    margin: 0 0 2rem 0; }
    @media only screen and (min-width: 768px) {
      .page-contacts__title {
        font-size: 5vw; } }
    @media only screen and (min-width: 992px) {
      .page-contacts__title {
        font-size: 2rem;
        font-size: 3vw; } }
    @media only screen and (min-width: 1920px) {
      .page-contacts__title {
        font-size: 2.75rem; } }
  .page-contacts-group:not(:first-child) {
    margin-top: 36px;
    margin-top: 5vh; }
  .page-contacts-group__title {
    margin-bottom: 16px;
    font-weight: bold; }
  .page-contacts__phone, .page-contacts__email {
    padding: 8px 0;
    display: block;
    color: #fff;
    transition: color 0.36s ease; }
    .page-contacts__phone:hover, .page-contacts__email:hover {
      color: #ff478c; }
  .page-contacts__phone {
    font-family: "Gilroy", sans-serif;
    font-weight: bold;
    font-size: 1.25rem;
    letter-spacing: 0.05em; }
  .page-contacts__email {
    text-transform: lowercase;
    letter-spacing: 0.2em; }
  .page-contacts__info {
    padding-right: 16px; }
  .page-contacts__map {
    min-height: 300px;
    filter: grayscale(1) invert(90%); }
  .page-contacts__col {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
